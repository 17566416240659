import axios from "@/utility/axios";


class FormService {
    static async getProperties() {
        return new Promise((resolve, reject) => {
            axios.get('form/properties', 'https://pp.we2p.de/api/properties').then(function (response) {
                resolve(
                    response.data
                );
            }).catch(function (error) {
                reject(
                    error
                );
            });
        });
    }

    static async getTypes() {
        return new Promise((resolve, reject) => {
            axios.get('form/types', '/api/config/form/types').then(function (response) {
                resolve(
                    response.data.result
                );
            }).catch(function (error) {
                reject(
                    error
                );
            });
        });
    }
}

export default FormService
