import router from '../../router';
import CoreService from "@/services/CoreService";

// STATE
const initialState = () => ({
    cores: [],
    currentCore: {
        id: undefined
    }
});

export default {
    state: initialState(),
    getters: {
        allCores: (state) => state.cores,
        currentCore: (state) => state.currentProject
    },
    actions: {
        async getCores({commit}) {
            return await CoreService.getCores();

        },
        async fetchCores({commit}) {
            // id = id from currently selected project
            const response = await CoreService.getCore(router.currentRoute.params.id);
            commit('setCores', response);
        },
        setCurrentCore({commit}, core) {
            commit('setCurrentCore', core)
        },
        reset({commit}) {
            commit('RESET');
        },
    },
    mutations: {
        setCores: ((state, cores) => {
            state.cores = cores;
            if (state.currentProject._id === "0" && cores.length > 0) {
                const core = cores.filter(obj => {
                    return obj.id === router.currentRoute.params.coreId;
                });

                state.currentCore = core[0];
            }
        }),
        setCurrentCore: (state, core) => state.currentCode = core,
        RESET(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        }
    }
}
