import { render, staticRenderFns } from "./FontConfigurationSection.vue?vue&type=template&id=f690e374&scoped=true"
import script from "./FontConfigurationSection.vue?vue&type=script&lang=js"
export * from "./FontConfigurationSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f690e374",
  null
  
)

export default component.exports