// VueJS
import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store';

// Libraries
import VueMaterial from 'vue-material'
import VueMoment from 'vue-moment'
import MomentTimezone from 'moment-timezone'
import Toasted from 'vue-toasted';
import VueCookies from 'vue-cookies'
import vuetify from './plugins/vuetify';

// CSS/SCSS
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import './assets/styles/custom.scss';

// Components
import App from './App.vue'
import ToastedMixin from "@/components/mixins/ToastedMixin";

// * * * * * * * * * * * * * * *
//
//                             *
//          Vuetify            *
//                             *
// * * * * * * * * * * * * * * *

Vue.use(VueCookies);

// set default config
Vue.$cookies.config('30d')

// set global cookie
Vue.$cookies.set('dark', 'false');

// * * * * * * * * * * * * * * *
//                             *
//        Vue Material         *
//                             *
// * * * * * * * * * * * * * * *
Vue.use(VueMaterial)

// * * * * * * * * * * * * * * *
//                             *
//         Vue Moment          *
//                             *
// * * * * * * * * * * * * * * *
Vue.use(
    VueMoment,
    MomentTimezone,
);

// * * * * * * * * * * * * * * *
//                             *
//         Vue Toasted         *
//                             *
// * * * * * * * * * * * * * * *
Vue.use(Toasted);

Vue.toasted.register('error', (payload) => {
    if (!payload) {
        return "Oops.. Something Went Wrong.."
    }

    return "Oops.. " + payload;
}, {
    theme: "bubble",
    type: 'error',
    icon: 'error_outline',
    duration: 5000
});

Vue.toasted.register('success', (payload) => {
    if (!payload) {
        return "Successfully"
    }

    return "Successfully " + payload;
}, {
    theme: "bubble",
    type: 'success',
    icon: 'check_circle_outline',
    duration: 5000
});

// register toasted mixin
Vue.mixin(ToastedMixin)

import VJsoneditor from 'v-jsoneditor'

Vue.use(VJsoneditor)

// * * * * * * * * * * * * * * *
//                             *
//         Vue Router          *
//                             *
// * * * * * * * * * * * * * * *
Vue.use(VueRouter)

const prod = process.env.NODE_ENV !== 'development'
const shouldSW = 'serviceWorker' in navigator && prod
if (shouldSW) {
    navigator.serviceWorker.register('/service-worker.js').then(() => {
        console.log("Service Worker Registered!")
    })
}

// Register a global custom directive called v-focus
Vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
        // Focus the element
        el.focus()
    }
})


// * * * * * * * * * * * * * * *
//                             *
//             Vue             *
//                             *
// * * * * * * * * * * * * * * *
Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
