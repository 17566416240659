<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Maps
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <!-- debug | elevation profile  -->
      <v-row>
        <v-col cols="6">
          <v-checkbox
              v-model="data.MAP.debug"
              label="Debugging"
              color="red"
              hide-details
          />
        </v-col>

        <v-col cols="6">
          <v-checkbox
              v-model="data.MAP.elevationProfile"
              label="Höhenprofil"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>

      <!-- data limit -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="Maximale Anzahl der Datensätze"
              v-model="data.MAP.limit"
          />
        </v-col>

        <v-col cols="6">
        </v-col>
      </v-row>

      <!-- min zoom | max zoom -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="Max Zoom"
              v-model="data.MAP.maxZoom"
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
              label="Max Zoom"
              v-model="data.MAP.minZoom"
          />
        </v-col>
      </v-row>

      <!-- max bounds | center -->
      <v-row>
        <v-col cols="6">
          <v-text-field
              label="Max Bounds ([[lat, lng], [lat, lng]])"
              v-model="data.MAP.maxBounds"
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
              label="Center ([lat, lng])"
              v-model="data.MAP.center"
          />
        </v-col>
      </v-row>

      <!-- trail color | trail width -->
      <v-row class="py-4">
        <v-col>
          <div style="border-bottom-style: solid">Tourenverlauf</div>
          <v-container fluid class="mt-4 px-0">
            <v-row>
              <v-col cols="6">
                <div class="caption grey--text">Farbe des Tourenverlaufs ({{
                    data.VIEW.styles.colors.primary
                  }})
                </div>
                <ColorInput v-model="data.MAP.trail.color"
                            :default="data.VIEW.styles.colors.primary"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                    label="Breite des Tourenverlaufs in px"
                    v-model="data.MAP.trail.width"
                />
              </v-col>
            </v-row>
            </v-container>
        </v-col>
      </v-row>

      <v-row class="py-4">
        <v-col>
          <div class="mb-2" style="border-bottom-style: solid">Filterlinks</div>
          <v-container fluid class="pa-0">
            <v-row>
              <v-col cols="6">
                <v-text-field :label="'Restaurants (' + defaultData.MAP.nearby.foodEstablishment.filter + ')'"
                              required
                              v-model="data.MAP.nearby.foodEstablishment.filter"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field :label="'Unterkünfte (' + defaultData.MAP.nearby.lodgingBusiness.filter + ')'"
                              required
                              v-model="data.MAP.nearby.lodgingBusiness.filter"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="caption grey--text">Restaurants Farbe ({{
                    defaultData.MAP.nearby.foodEstablishment.color
                  }})
                </div>
                <ColorInput v-model="data.MAP.nearby.foodEstablishment.color"
                            :default=" defaultData.MAP.nearby.foodEstablishment.color"
                />
              </v-col>
              <v-col cols="6">
                <div class="caption grey--text">Unterkünfte Farbe ({{
                    defaultData.MAP.nearby.lodgingBusiness.color
                  }})
                </div>
                <ColorInput v-model="data.MAP.nearby.lodgingBusiness.color"
                            :default="defaultData.MAP.nearby.lodgingBusiness.color"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field :label="'Touristenattraktionen (' + defaultData.MAP.nearby.touristAttraction.filter + ')'"
                              required
                              v-model="data.MAP.nearby.touristAttraction.filter"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field :label="'Wanderungen (' + defaultData.MAP.nearby.trail.filter + ')'"
                              required
                              v-model="data.MAP.nearby.trail.filter"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <div class="caption grey--text">Touristenattraktionen Farbe ({{
                    defaultData.MAP.nearby.touristAttraction.color
                  }})
                </div>
                <ColorInput v-model="data.MAP.nearby.touristAttraction.color"
                            :default="defaultData.MAP.nearby.touristAttraction.color"
                />
              </v-col>
              <v-col cols="6">
                <div class="caption grey--text">Wanderungen Farbe ({{
                    defaultData.MAP.nearby.trail.color
                  }})
                </div>
                <ColorInput v-model="data.MAP.nearby.trail.color"
                            :default=" defaultData.MAP.nearby.trail.color"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field :label="'ÖPNV (' + defaultData.MAP.nearby.oepnv.filter + ')'"
                              required
                              v-model="data.MAP.nearby.oepnv.filter"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field :label="'ÖPNV Icon (' + defaultData.MAP.nearby.oepnv.image + ')'"
                              required
                              v-model="data.MAP.nearby.oepnv.image"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="caption grey--text">ÖPNV Farbe ({{
                    defaultData.MAP.nearby.oepnv.color
                  }})
                </div>
                <ColorInput v-model="data.MAP.nearby.oepnv.color"
                            :default="defaultData.MAP.nearby.oepnv.color"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";
import ColorInput from "@/components/misc/ColorInput";

export default {
  name: "MapsConfigurationSection",
  components: {ColorInput},
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>
.col {
  padding-top: 0 !important;
}
</style>
