import axios from "axios";

class SearchService {

    static search(scope, query) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/search/${scope}/${query}`).then((res) => {
                resolve(res.data.result)
            }).catch((err) => {
                reject(err);
            })
        });
    }
}

export default SearchService;
