<template>
  <div>
    <v-container class="my-5">
      <v-progress-linear v-if="isLoading"
                         color="blue"
                         indeterminate
      ></v-progress-linear>

      <v-card flat class="pa-3 mb-1" :color="background"
              v-for="(project, index) in projects"
              v-bind:item="project"
              v-bind:index="index"
              v-bind:key="project._id">
        <v-layout row wrap class="px-4">
          <!-- ROW 1 -->
          <v-flex>
            <div class="caption grey--text">{{
                project.addon ? 'Unterprojekt' : "Projekt (" + currentProject._id + ")"
              }}
            </div>

            <p class="text-h5 mb-0">
              <v-btn fab text small dense :color="color" router
                     :to="`/project/${currentProject._id}/${project.domain}`">
                <v-icon>mode_edit</v-icon>
              </v-btn>

              {{ project.domain }}
            </p>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ProjectService from "@/services/ProjectService";
import GlobalMixin from "@/components/mixins/GlobalMixin";

export default {
  name: "ProjectListing",
  mixins: [GlobalMixin],
  data() {
    return {
      project: undefined,
      projects: [], // as array
      isLoading: false
    }
  },
  async created() {
    this.isLoading = true;

    if (this.currentProject._id !== "0")
      await this.getProjects();
  },
  computed: {
    currentProject: {
      get() {
        return this.$store.state.projects.currentProject;
      }
    }
  },
  watch: {
    async currentProject(newObj, oldObj) {
      if (newObj._id !== "0") {
        await this.getProjects();
      }
    }
  },
  methods: {
    async getProjects() {
      // Clear array
      this.projects = [];

      this.isLoading = true;
      this.project = await ProjectService.getProjectById(this.currentProject._id);

      if (this.project) {
        // MAIN Project
        this.project.addon = false;
        this.projects.push({
          domain: this.project.domain,
          description: this.description,
          addon: false
        });

        // ADDON Project
        if (this.project.configCollection) {
          this.project.configCollection.some((collection) => {
            this.projects.push({
              domain: collection.domain,
              addon: true
            });
          });
        }

        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
