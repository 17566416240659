<template>
  <div>
    <Project/>
  </div>
</template>

<script>
import Project from "@/components/project/Project";

export default {
  name: "Details",
  components: {Project},
  props: [
    'id',
    'domain'
  ]
}
</script>

<style scoped>

</style>
