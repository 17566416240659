<template>
  <div class="routerview">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "PlainRouterView"
}
</script>

<style scoped>

</style>
