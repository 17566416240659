import Vue from 'vue'
import VueRouter from 'vue-router'
// Library
import Auth from '@okta/okta-vue'
// Pages
//  Common
import Home from '@/views/Home'
import NotFound from "@/views/NotFound";
//  Project
import ProjectListing from "@/views/project/Listing";
import Project from "@/views/project/Project";
import Configuration from "@/components/project/configuration/Configuration";
import Language from "@/components/project/Language";
import Forms from "@/components/project/form/Forms";
//  Core
import CoreListing from "@/views/core/Listing";
import Core from "@/views/core/Core";
//  Misc
import Backwards from "@/components/misc/Backwards";
import PlainRouterView from "@/components/misc/PlainRouterView";
import Files from "@/views/Files";
import Cores from "../views/Cores";

Vue.use(VueRouter)

Vue.use(Auth, {
    issuer: 'https://auth.we2p.de/oauth2/aus15xi389Y2xKkUY4x7',
    client_id: '0oa14djcl6debDbZS4x7',
    redirect_uri: window.location.origin + '/implicit/callback',
    scope: 'openid profile email'
});

const routes = [
    {
        path: '/implicit/callback',
        name: 'Okta-Callback',
        component: Auth.handleCallback()
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/files',
        name: 'Files',
        component: Files,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/cores',
        name: 'Cores',
        component: Cores,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/project/:id',
        component: PlainRouterView,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "/",
                component: ProjectListing
            },
            {
                path: "cores",
                component: Backwards,
                children: [
                    {
                        path: "",
                        component: CoreListing
                    },
                    {
                        path: ":coreId",
                        component: PlainRouterView,
                        children: [
                            {
                                path: "",
                                component: Core
                            }
                        ]
                    }
                ]
            },
            {
                path: ":domain",
                component: Backwards,
                children: [
                    {
                        path: "",
                        component: Project,
                        children: [
                            {
                                path: "config",
                                component: Configuration
                            },
                            {
                                path: "language",
                                component: Language
                            },
                            {
                                path: "forms",
                                component: Forms
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/404',
        name: "404",
        meta: {
            requiresAuth: true
        },
        component: NotFound
    },
    {
        path: '*',
        redirect: '/404',
        meta: {
            requiresAuth: true
        }
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(Vue.prototype.$auth.authRedirectGuard())

export default router
