<template>
  <div>
    <v-container v-if="selectedProject">
      <v-row>
        <v-col>
          <h2>{{ selectedProject.addon ? 'Unterprojekt' : 'Projekt' }}</h2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <!-- Details -->
        <v-col>
          <!-- Domain -->
          <div class="font-weight-medium">Domain</div>
          <a :href='"https://" + selectedProject.domain' target="_blank">
            {{ selectedProject.domain }}
          </a>

          <!-- Config (MongoDB ID) -->
          <div class="font-weight-medium">Config</div>
          <div>
            {{ selectedProject.config ? selectedProject.config : 'Nicht hinterlegt' }}
          </div>
        </v-col>

        <v-col v-if="selectedProject.tags">
          <!-- Tags -->
          <div class="font-weight-medium">Tags</div>
          <div class="py-1" v-for="(tag, index) in selectedProject.tags"
               v-bind:item="tag"
               v-bind:index="index"
               v-bind:key="tag">
            <v-chip outlined>{{ tag }}</v-chip>
          </div>
        </v-col>

        <!-- Description -->
        <v-col v-if="!selectedProject.addon">
          <div v-if="selectedProject.description">
            <div class="font-weight-medium">Server</div>
            <!-- Description/Server -->
            <div v-if="selectedProject.description.server">
              <a :href='"https://" + selectedProject.description.server' target="_blank">
                {{ selectedProject.description.server }}
              </a>
            </div>
            <div v-else>
              <p>Nicht angegeben!</p>
            </div>

            <div class="font-weight-medium">Domain Registry</div>
            <!-- Description/Domain registry -->
            <div v-if="selectedProject.description.domReg">
              <a :href='"https://" + selectedProject.description.domReg' target="_blank">
                {{ selectedProject.description.domReg }}
              </a>
            </div>
            <div v-else>
              <p>Nicht angegeben!</p>
            </div>
          </div>
        </v-col>

        <!-- Dataset infos -->
        <v-col>
          <div class="font-weight-medium">Erstellt</div>
          <div>
            {{ convertDate(selectedProject.createdAt) }}
          </div>

          <!-- Updated -->
          <div class="font-weight-medium">Aktualisiert</div>
          <div>
            {{ convertDate(selectedProject.updatedAt) }}
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-divider class="mt-5 mb-5"></v-divider>

    <router-view v-if="!loading"></router-view>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import GlobalMixin from "@/components/mixins/GlobalMixin";
import {mapGetters, mapActions} from 'vuex';
import ProjectService from "@/services/ProjectService";
import ProjectMixin from "@/components/mixins/ProjectMixin";

export default {
  name: "Project",
  mixins: [GlobalMixin, ProjectMixin],
  data() {
    return {
      id: undefined,
      domain: undefined,
      project: undefined,
      loading: false
    }
  },
  async created() {
    this.loading = true;
    this.id = this.$router.currentRoute.params.id;
    this.domain = this.$router.currentRoute.params.domain;
    await this.getProject();
    this.loading = false;
  },
  computed: {
    selectedProject: {
      get() {
        return this.$store.state.projects.selectedProject;
      },
      set(project) {
        this.setSelectedProject(project);
      }
    }
  },
  methods: {
    ...mapActions(["setSelectedProject"]),
    async getProject() {
      if (this.domain) {
        const project = await ProjectService.getProjectWhereDomain(this.domain);

        if (project) {
          this.selectedProject = project;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
