<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          COMET (PDFs)
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col>
          <v-text-field
              v-model="data.COMET.url"
              label="URL"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              v-model="data.COMET.client"
              label="Client"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              v-model="data.COMET.domain"
              label="Domain"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              v-model="data.COMET.project_identifier"
              label="Project Identifier "
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "CometConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
