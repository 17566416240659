<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Spacing
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col>
          <v-text-field
              :color="color"
              :label="'Margin Top (' + defaultData.VIEW.styles.margin.top + ')'" required
              v-model="data.VIEW.styles.margin.top"
          />
        </v-col>
        <v-col>
          <v-text-field
              :color="color"
              :label="'Margin Bottom (' + defaultData.VIEW.styles.margin.bottom + ')'"
              required
              v-model="data.VIEW.styles.margin.bottom"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
              :color="color"
              :label="'Margin Left (' + defaultData.VIEW.styles.margin.left + ')'"
              required
              v-model="data.VIEW.styles.margin.left"
          />
        </v-col>

        <v-col>
          <v-text-field
              :color="color"
              :label="'Margin Right (' + defaultData.VIEW.styles.margin.right + ')'"
              required
              v-model="data.VIEW.styles.margin.right"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "SpacingConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
