<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Result
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col>
          <v-text-field
              label="404 Seite"
              required
              v-model="data.RES.error_pages['404']"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "ResultConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
