export default {
    data() {
        return {
            shouldSave: false,
            typeRules: [
                (v) => {
                    if (!v) {
                        return 'Typ erforderlich!'
                    }
                    return true;
                }
            ],
            // style
            background: undefined,
            color: undefined,
            dark: false
        }
    },
    created() {
        this.set();
    },
    watch: {
        '$vuetify.theme.dark': {
            handler(next) {
                this.set();
            },
            deep: true
        }
    },
    methods: {
        set() {
            if (this.$vuetify.theme.dark) {
                this.background = "secondary";
                this.color = 'white';
                this.dark = true;
            } else {
                this.background = 'white';
                this.color = 'primary';
                this.dark = false;
            }
        },
        /**
         * Redirects the user to the specified route.
         * @param route {Object}
         * @returns {Promise<void>}
         */
        async redirectTo(route) {
            await this.$router.push(route);
        },
        /**
         * Redirects the user to the NotFound.vue page.
         * @returns {Promise<void>}
         */
        async notFound() {
            await this.$router.push({name: '404'});
            this.$toasted.global.error();
        },
        convertDate(time) {
            return this.$moment.utc(time).local().format('DD.MM.YYYY HH:mm');
        }
    }
};
