<template>
  <div class="not-found">
    <v-container>
      <v-row>
        <v-col class="text-center">
          <h1 class="display-4">404</h1>
          <h2 class="display-2 grey--text">Not Found</h2>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "NotFound.vue"
}
</script>

<style scoped>

</style>
