<template>
  <v-container class="pl-4 pr-4">
    <v-row>
      <v-col cols="5">
        <v-select class="p-0"
                  :items="fileTypes"
                  item-text="Typ"
                  v-model="fileType"
                  label="Typ"
                  prepend-icon="topic"
                  return-object
        />
      </v-col>
      <v-col cols="5">
        <v-select class="p-0"
                  :items="languageCodes"
                  item-text="Sprache"
                  v-model="languageCode"
                  label="Sprache"
                  prepend-icon="flag"
                  return-object
        />
      </v-col>
      <v-col cols="2">
        <v-btn class="mt-6" color="green" text v-on:click="fetchFile">Datei laden</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="json">
      <v-jsoneditor v-model="json" :plus="false" :options="{mode: 'code', onChangeText: jsonChanged}" height="1000px"/>
    </v-row>
    <v-row>
      <v-col v-if="shouldSave">
        <SaveDialog :shouldSave="shouldSave" :tryToQuit="undefined" @save="save"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileService from "@/services/FileService";
import GlobalMixin from "@/components/mixins/GlobalMixin";
import SaveDialog from "@/components/misc/SaveDialog";

export default {
  name: "Files",
  components: {SaveDialog},
  mixins: [GlobalMixin],
  data() {
    return {
      fileTypes: ["config", "messages", "features"],
      fileType: undefined,
      languageCodes: ["DE", "EN", "FR"],
      languageCode: undefined,
      shouldSave: false,
      json: undefined
    }
  },
  methods: {
    async fetchFile() {
      if (!this.fileType) {
        this.sendToast("file_missing_attribute", "error");
        return;
      }

      if (this.shouldSave) {
        this.sendToast("not_saved", "error");
        this.shouldSave = false;
      }

      switch (this.fileType) {
        case "messages":
        case "features": {
          if (!this.languageCode) {
            this.sendToast("file_missing_attribute", "error");
            return;
          }

          const languageSet = await FileService.getDefaultTranslation(this.fileType, this.languageCode);

          if (languageSet) {
            this.json = languageSet.languageSet;
          }

          break;
        }
        case "config": {
          const config = await FileService.getDefaultConfig();

          if (config) {
            this.json = config.config;
          }
          break;
        }
      }
    },
    jsonChanged(json) {
      this.shouldSave = true;
    },
    save() {
      this.shouldSave = false;

      switch (this.fileType) {
        case "messages":
        case "features": {
          FileService.saveDefaultTranslation(this.fileType, this.languageCode, this.json);
          break;
        }
        case "config": {
          FileService.saveDefaultConfig(this.json);
          break;
        }
      }
    }
  }
}
</script>
