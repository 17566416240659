export default {
    props: [
        "defaultData",
        "data",
        "color"
    ],
    watch: {
        data() {
            this.$emit("changed", this.data);
        }
    }
};
