import axios from "@/utility/axios";

class FileService {

    static getDefaultTranslation(translation, language) {
        return new Promise((resolve, reject) => {
            axios.get('file/translation', `/api/language/default/${translation}/${language}`).then((res) => {
                resolve(
                    res.data.result
                );
            }).catch((err) => {
                reject(err);
            })
        });
    }

    static async saveDefaultTranslation(translation, language, languageSet) {
        return new Promise((resolve, reject) => {
            axios.patch('file/default/translation', `/api/language/default/${translation}/${language}`, languageSet).then((res) => {
                resolve(
                    res.data.result
                );
            }).catch((err) => {
                reject(err);
            })
        });
    }

    static async getDefaultConfig() {
        return new Promise((resolve, reject) => {
            axios.get('file/default', `/api/config/default`).then((res) => {
                resolve(
                    res.data.result
                );
            }).catch((err) => {
                reject(err);
            })
        });
    }

    static async saveDefaultConfig(config) {
        return new Promise((resolve, reject) => {
            axios.patch('file/default/save', `/api/config/default`, config).then((res) => {
                resolve(
                    res.data.result
                );
            }).catch((err) => {
                reject(err);
            })
        });
    }
}

export default FileService;
