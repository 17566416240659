import axios from "@/utility/axios";

const url = '/api/config/';

class ConfigService {

    static dontInjectCORS = [
        'www.bergstrasse-odenwald.de'
    ]

    static createConfig(domain) {
        return new Promise((resolve, reject) => {
            axios.post('config/create', url + domain).then((res) => {
                resolve(
                    res.data.result
                )
            }).catch((err) => {
                reject(err);
            })
        });
    }

    static createWithTemplateConfig(domain, template) {
        return new Promise((resolve, reject) => {
            axios.post('config/template', url + domain + '/' + template).then((res) => {
                resolve(
                    res.data.result
                )
            }).catch((err) => {
                reject(err);
            })
        });
    }

    // DEFAULT Config
    static getDefaultConfig() {
        return new Promise((resolve, reject) => {
            axios.get('config/default', url + "default").then((res) => {
                resolve(
                    res.data.result
                );
            }).catch((err) => {
                reject(err);
            })
        });
    }

    // GET config by id
    static getConfig(id, merge = false) {
        return new Promise((resolve, reject) => {
            axios.get('config/get', url + id + '/' + merge)
                .then((res) => {
                    resolve(
                        res.data.result
                    )
                }).catch((err) => {
                reject(err);
            })
        });
    }

    static getForms(id) {
        return new Promise((resolve, reject) => {
            axios.get('config/forms', url + 'forms/' + id)
                .then((res) => {
                    resolve(
                        res.data.result
                    )
                }).catch((err) => {
                reject(err);
            })
        });
    }

    static updateConfig(domain, config) {
        return new Promise((resolve, reject) => {
            axios.patch('config/update', url + domain, config).then((res) => {
                resolve(
                    res.data.result
                )
            }).catch((err) => {
                reject(err);
            })
        });
    }

    static updateForms(domain, forms) {
        return new Promise((resolve, reject) => {
            axios.patch('config/update/forms', url + 'forms/' + domain, forms).then((res) => {
                resolve(
                    res.data.result
                )
            }).catch((err) => {
                reject(err);
            })
        });
    }
}

export default ConfigService;
