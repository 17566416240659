<template>
  <v-card>
    <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
    >
      <v-toolbar-title class="headline">
        Forms
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-checkbox
          v-model="data.VIEW.forms.show"
          label="Anzeigen"
          color="green"
          hide-details
      />
    </v-app-bar>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col>
          <v-text-field :label="'Icon (' + defaultData.VIEW.forms.icon + ')'"
                        required
                        v-model="data.VIEW.forms.icon"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :label="'Forms URL (' + defaultData.VIEW.forms.url + ')'"
                        required
                        v-model="data.VIEW.forms.url"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "FormsConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>
</style>
