import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueCookies from 'vue-cookies'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: (VueCookies.get('dark') === 'true'),
        options: {
            customProperties: true
        },
        themes: {
            dark: {
                "primary": "#0d1117",
                "secondary": "#24292e",
                "stepper-elevation": 0,
                "stepper-header-elevation": 0,
                'background': '#eb4d4b'
            },
            light: {
                //"primary": "#686de0",
                //"secondary": "#4834d4",

                //"primary": "#4b6584",
                //"secondary": "#778ca3",

                "primary": "#043353",
                "secondary": "#4b6584",

                "stepper-elevation": 0,
                "stepper-header-elevation": 0
            }
        }
    }
});
