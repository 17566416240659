<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Discussions
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" fluid>
      <v-row>
        <v-col cols="4" class="pt-0">
          <v-switch
              label="Aktivieren?"
              v-model="data.DISCUSSIONS.active"
          ></v-switch>
        </v-col>

        <v-col>
          <v-container fluid>
            <v-row v-if="data.DISCUSSIONS.active">
              <v-col cols="4" class="pt-0">
                <v-switch
                    label="Kommentare aktivieren?"
                    v-model="data.DISCUSSIONS.comment_section.active"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="data.DISCUSSIONS.active" cols="4">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field label="Projekt"
                              required
                              v-model="data.DISCUSSIONS.project"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Channel"
                              required
                              v-model="data.DISCUSSIONS.channel"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col v-if="data.DISCUSSIONS.comment_section.active" cols="4">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field label="Environment"
                              required
                              v-model="data.DISCUSSIONS.comment_section.env"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-combobox
                    :color="color"
                    v-model="data.DISCUSSIONS.comment_section.types"
                    :items="data.DISCUSSIONS.comment_section.types"
                    label="Types"
                    multiple
                    small-chips
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Antwort-Icon-Url"
                              required
                              v-model="data.DISCUSSIONS.comment_section.answer_icon_url"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-switch
                    label="Abrundungen?"
                    v-model="data.DISCUSSIONS.comment_section.rounding"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "DiscussionsConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
