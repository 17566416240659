<template>
  <div v-if="!loading">
    <v-container>
      <v-row v-if="!selectedProject">
        <v-col class="text-center pt-3">
          <v-icon size="128" class="mb-4">error_outline</v-icon>
          <div>Kein Projekt ausgewählt</div>
        </v-col>
      </v-row>
      <v-row v-else-if="!forms || forms.length <= 0">
        <v-col class="text-center pt-3">

          <v-icon size="128" class="mb-4">post_add</v-icon>
          <div>Es wurde noch keine Form erstellt</div>
        </v-col>
      </v-row>
      <v-row v-else-if="!selectedProject.config">
        <v-col class="text-center pt-3">
          <v-icon size="128" class="mb-4">text_snippet</v-icon>
          <h3 class="pt-5">Es wurde noch keine Config hinterlegt!</h3>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-expansion-panels focusable>
            <template v-for="(form) in forms">
              <v-expansion-panel :key="form.id" :color="color">

                <!-- PANEL HEAD -->
                <v-expansion-panel-header>
                  <v-container>
                    <v-row no-gutters class="pr-6 pl-6">
                      <v-col cols="10" class="text-body-1">
                        <div class="body-1">
                          <a :href='`https://${selectedProject.domain}/we2p/api/?controller=forms&action=index&name=${form.name}`'
                             target="_blank">{{
                              form.name + ' ' + (form.title ? '&lt;' + form.title + '&gt;' : '')
                            }}</a>
                          <v-icon v-if="form.privacy" class="green--text">privacy_tip</v-icon>
                        </div>
                        <div class="grey--text body-2" v-if="form.types">
                          {{ `Type: ${form.types[0]}` }}
                        </div>
                        <div class="grey--text body-2" v-if="form.label">
                          {{ `Label: ${form.label}` }}
                        </div>
                        <div class="grey--text body-2">
                          {{ `E-Mail ${form.email}` }}
                        </div>
                      </v-col>

                      <v-col class="text-right">
                        <v-row>
                          <v-col>
                            <!-- Copy link to clipboard -->

                          </v-col>
                          <v-col>
                            <!-- Edit mode -->
                            <FormDialog
                                :reference="{obj: form}"
                                @callback="formDialogCallback"
                            />
                          </v-col>
                          <v-col>
                            <DeleteDialog :criteria="{type: 'form', id: form.id}"
                                          @delete="deleteData"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-header>

                <!-- PANEL CONTENT -->
                <v-expansion-panel-content>
                  <v-container>
                    <v-row no-gutters>
                      <v-col>
                        <v-card flat tile>
                          <!-- Fields -->
                          <draggable v-model="form.layout" v-bind="dragOptions" group="people" handle=".handle"
                                     @start="drag=true" @end="drag=false">
                            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                              <template v-for="(field, index) in form.layout">
                                <v-list-item :key="field.id" :index="index">
                                  <template>
                                    <v-list-item-avatar style="cursor: grab" class="handle">
                                      <v-icon>
                                        drag_handle
                                      </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                      <div class="font-weight-bold">{{ field.dataBinding }}
                                        {{ field.type ? '&lt;' + field.type + '&gt;' : null }}
                                      </div>
                                      <div class="orange--text">
                                        {{ field.label ? field.label : 'Standard!' }}
                                      </div>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                      <v-row>
                                        <v-col>
                                          <!-- Edit mode active -->
                                          <FieldDialog :form-name="form.name"
                                                       :reference="{obj: field}"
                                                       @callback="fieldDialogCallback"
                                          />
                                        </v-col>
                                        <v-col>
                                          <DeleteDialog :criteria="{type: 'field', form: form.name, id: field.id}"
                                                        :byPassConfirmation="true"
                                                        :attrs="{btnColor: '#B71C1C', btnIcon: 'remove'}"
                                                        @delete="deleteData"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-list-item-action>
                                  </template>
                                </v-list-item>
                              </template>
                            </transition-group>
                          </draggable>

                          <v-list-item>
                            <template>
                              <v-list-item-avatar>
                                <FieldDialog
                                    :attrs="{btnColor: 'lightgray'}"
                                    :form-name="form.name"
                                    :properties="properties"
                                    @callback="fieldDialogCallback"/>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <div class="font-italic grey--text">
                                  Feld hinzufügen
                                </div>
                              </v-list-item-content>
                            </template>

                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row v-if="selectedProject && selectedProject.config" class="text-right">
        <v-col class="pl-5">
          <FormDialog @callback="formDialogCallback"/>

          <SaveDialog
              :shouldSave="shouldSave"
              :tryToQuit="tryToQuit"
              @save="save"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else>
    <v-progress-linear></v-progress-linear>
  </div>
</template>

<script>
import FormService from "@/services/FormService";
import ConfigService from "@/services/ConfigService";
import DeleteDialog from "@/components/misc/DeleteDialog";
import FieldDialog from "@/components/project/form/FieldDialog";
import SaveDialog from "@/components/misc/SaveDialog";
import FormDialog from "@/components/project/form/FormDialog";

import draggable from "vuedraggable";
import {v4 as uuidv4} from 'uuid';
import GlobalMixin from "@/components/mixins/GlobalMixin";
import ToastedMixin from "@/components/mixins/ToastedMixin";

const _ = require('lodash');

/**
 * Form data structure
 *
 * {
 *   name: 'TEST-Form',,
 *   email: 'test@test.de',
 *   title: 'TEST-Form',
 *   label: 'Neues Event anlegen',
 *   projectIdentifier: 'test', // Module!
 *   types: [
 *     'Thing'
 *   ],
 *   layout: [
 *     {
 *        'dataBinding': 'streetAddress',
 *        'label': 'Straße & Hausnummer',
 *        'type': 'String'
 *     },
 *     {
 *        'dataBinding': 'postalCode',
 *        'label': 'Postleitzahl',
 *        'type': 'String'
 *     }
 *   ]
 * }
 */

export default {
  name: "Forms",
  components: {FormDialog, SaveDialog, FieldDialog, DeleteDialog, draggable},
  mixins: [GlobalMixin, ToastedMixin],
  data() {
    return {
      loading: false,
      properties: undefined,
      config: undefined,
      forms: [],
      formsCopy: undefined,
      shouldSave: false,
      tryToQuit: undefined,
      drag: false
    }
  },
  async created() {
    this.loading = true;
    this.properties = await FormService.getProperties();

    if (this.selectedProject) {
      await this.initializeConfig();
    }

    this.shouldSave = false;
    this.loading = false;
  },
  beforeRouteLeave(to, from, next) {
    if (this.selectedProject && this.selectedProject.config) {
      this.tryToQuit = next;
    } else {
      next();
    }
  },
  watch: {
    forms: {
      handler(data) {
        if (!this.shouldSave) {
          if (!_.isEqual(this.formsCopy, data)) {
            this.shouldSave = true;
          }
        }
      },
      deep: true
    },
    async selectedProject(newObj, oldObj) {
      if (oldObj._id === "0") {
        await this.initializeConfig();
      }
    }
  },
  computed: {
    selectedProject: {
      get() {
        return this.$store.state.projects.selectedProject;
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    async initializeConfig() {
      this.config = await ConfigService.getForms(this.selectedProject.config);
      if (this.config) {
        this.forms = (this.config && this.config.config ? this.config.config.FORMS : [])

        if (this.forms) {
          // index all forms and layouts
          this.forms.forEach((form) => {
            form.id = uuidv4();

            if (form.layout) {
              form.layout.forEach((field) => {
                field.id = uuidv4();
              });
            }
          });

          this.formsCopy = _.cloneDeep(this.forms);
        }
      }
    },
    deleteData(res) {
      if (res.delete) {
        const criteria = res.criteria;
        if (criteria) {
          const id = criteria.id;

          if (id) {
            switch (criteria.type) {
              case 'form': {
                const index = this.forms.findIndex(x => x.id === id);
                this.forms.splice(index, 1);
                break;
              }

              case 'field': {
                this.forms.some((form) => {
                  if (form.name === criteria.form) {
                    const index = form.layout.findIndex(x => x.id === id);
                    form.layout.splice(index, 1);
                  }
                });
                break;
              }
            }
          }
        }
      }
    },
    fieldDialogCallback(callback) {
      switch (callback.action) {
        case 'add': {
          this.forms.some((form) => {
            if (form.name === callback.form) {
              callback.field.id = uuidv4();

              form.layout.push(callback.field);
              return true;
            }
          });

          break;
        }

        case 'edit': {
          this.forms.some((form) => {
            if (form.name === callback.form) {
              const index = form.layout.findIndex(x => x.id === callback.field.id);
              form.layout.splice(index, 1, callback.field);
              return true;
            }
          });
          break;
        }
      }
    },
    formDialogCallback(callback) {
      switch (callback.action) {
        case 'add': {
          callback.form.id = uuidv4();
          this.forms.push(callback.form);
          this.save();
          break;
        }

        case 'edit': {
          const index = this.forms.findIndex(x => x.id === callback.form.id);
          this.forms.splice(index, 1, callback.form);
          break;
        }
      }
    },
    save() {
      ConfigService.updateForms(this.selectedProject.domain, {
        id: this.selectedProject.config,
        forms: this.forms
      });

      this.formsCopy = _.cloneDeep(this.forms);
      this.shouldSave = false;
      this.sendToast("config_update", this.TOAST_TYPE.SUCCESS);
    }
  }
}
</script>

<style scoped>

</style>
