<template>
  <div>
    <Core/>
  </div>
</template>

<script>
import Core from "@/components/core/Core";

export default {
  name: "Details",
  components: {Core}
}
</script>

<style scoped>

</style>
