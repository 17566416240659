<template>
  <div class="cores">
    <CoreListing/>
  </div>
</template>

<script>
import CoreListing from "@/components/core/CoreListing";

export default {
  name: "Listing",
  components: {
    CoreListing
  },
  props: [
    'id'
  ]
}
</script>

<style scoped>

</style>
