import axios from "@/utility/axios";

const url = '/api/language';

class LanguageService {

    static getLanguage(domain, code) {
        return new Promise((resolve, reject) => {
            axios.get('language', `${url}/${domain}/messages/${code}/false/?empty=true`)
                .then((res) => {
                    resolve(
                        res.data.result?.languageSet
                    )
                }).catch((err) => {
                reject(err);
            })
        });
    }

    static create(domain, code) {
        return new Promise((resolve, reject) => {
            axios.post('language/create', `${url}/${domain}/${code}/messages`)
                .then((res) => {
                    resolve(
                        res.data.result
                    )
                }).catch((err) => {
                reject(err);
            })
        });
    }

    static save(languageSet, domain) {
        return new Promise((resolve, reject) => {
            axios.patch('language/save', `${url}/${domain}`, languageSet)
                .then((res) => {
                    resolve(
                        res.data.result
                    )
                }).catch((err) => {
                reject(err);
            })
        });
    }
}

export default LanguageService;
