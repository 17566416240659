<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="reference" fab depressed class="ml-3" :color="btnColor" small v-bind="attrs" v-on="on">
        <v-icon :color="color">
          {{ btnIcon ? btnIcon : 'edit' }}
        </v-icon>
      </v-btn>
      <v-btn v-else fab depressed :color="btnColor" small v-bind="attrs" v-on="on">
        <v-icon :color="color">
          {{ btnIcon ? btnIcon : 'add' }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ reference ? 'Feld editieren' : 'Feld hinzufügen' }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-select v-if="properties"
                        style="max-width: 300px"
                        :items="properties"
                        item-text="name"
                        v-model="property"
                        label="Datenfeld Typ"
                        :color="color"
                        prepend-icon="swap_horiz"
                        :rules="typeRules"
                        return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="property">
            <v-col>
              <v-text-field
                  v-model="field.label"
                  label="Label"
                  :color="color"
                  prepend-icon="code"
              />
            </v-col>
          </v-row>
          <v-row v-if="property">
            <v-col>
              <v-checkbox
                  v-model="field.required"
                  label="Pflichtfeld"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- Save -->
        <v-btn
            color="green darken-1"
            text
            @click="makeCallback">
          {{ reference ? 'Bearbeiten' : 'Hinzufügen' }}
        </v-btn>

        <!-- Close -->
        <v-btn
            color="blue darken-1"
            text
            v-on:click="dialog = false">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

/** ref Object
 *
 * 'ref': {
 *   'index': '0' // array index
 *   'obj': {     // object reference
 *
 *   }
 * }
 *
 */

import GlobalMixin from "@/components/mixins/GlobalMixin";

const _ = require('lodash');

export default {
  name: "FieldDialog",
  mixins: [
    GlobalMixin
  ],
  props: [
    'reference',
    'formName',
    'properties'
  ],
  data() {
    return {
      dialog: false,
      property: undefined,
      field: undefined,
      // Button style
      btnColor: 'lightgray',
      btnIcon: undefined
    }
  },
  created() {
    this.initialize();

    const attrs = this.$attrs.attrs;
    if (attrs) {
      if (attrs.btnColor) {
        this.btnColor = attrs.btnColor
      }

      if (attrs.btnIcon) {
        this.btnIcon = attrs.btnIcon;
      }
    }

    if (this.reference) {
      this.field = _.cloneDeep(this.reference.obj);
      this.property = true;
    }
  },
  dialog: {
    handler(val) {
      if (!val) {
        this.field.dataBinding = undefined;
        this.form.label = undefined;
        this.form.type = undefined;
      }
    }
  },
  methods: {
    initialize() {
      this.property = undefined;
      this.field = {
        dataBinding: undefined,
        label: undefined,
        type: undefined
      };
    },
    makeCallback() {
      if (this.reference) {
        // editing mode active
        this.$emit("callback", {
          action: "edit",
          form: this.formName,
          index: this.reference.index,
          field: this.field
        });
      } else {
        if (this.property) {
          this.field.dataBinding = this.property.name;
          this.field.label = (this.field.label ? this.field.label : this.property.label);
          this.field.required = (this.field.required ? this.field.required : this.property.required);
          this.field.type = this.property.type;

          this.$emit("callback", {
            action: "add",
            form: this.formName,
            field: this.field
          });

          this.initialize();
        }
      }

      this.dialog = false;
    }
  }
}
</script>
