<template>
  <div>
    <v-progress-linear v-if="isLoading"
                       indeterminate
                       color="blue"
                       height="6">
    </v-progress-linear>

    <v-data-table
        :headers="headers"
        :items="cores"
        class="elevation-1"
        :items-per-page="15"
    >
      <template v-slot:item.domain="{ item }">
        <p class="ma-0">{{ item._id }}</p>
        <p class="ma-0 font-weight-bold">{{ item.domain }}</p>
      </template>

      <template v-slot:item.tags="{ item }">
        <v-flex>
          <v-chip class="ma-1" outlined v-for="(tag, index) in item.tags"
                  v-bind:item="tag"
                  v-bind:index="index"
                  v-bind:key="tag">
            {{ tag }}
          </v-chip>
        </v-flex>
      </template>

      <!-- BRANCH -->
      <template v-slot:item.branch="{ item }">
        <div v-if="item.status">
          {{ item.status.branch }}
        </div>
        <v-icon v-else color="red">mobiledata_off</v-icon>
      </template>

      <!-- VERSION -->
      <template v-slot:item.version="{ item }">
        <div v-if="item.status">
          <p v-if="item.status.currentHash === item.status.newestHash" class="green--text ma-0 font-weight-bold">
            Aktuell
          </p>
          <p v-else class="red--text ma-0 font-weight-bold">
            Nicht aktuell
          </p>

          <!--
          <p class="ma-0"> Current: {{ item.status ? item.status.currentHash : '/' }}</p>
          <p class="ma-0"> Newest: {{ item.status ? item.status.newestHash : '' }}</p>
          -->
        </div>
        <v-icon v-else color="red">mobiledata_off</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import GlobalMixin from "../components/mixins/GlobalMixin";

export default {
  name: "Cores",
  mixins: [GlobalMixin],
  data() {
    return {
      isLoading: false,
      cores: [],
      headers: [
        {
          text: 'Domain',
          value: 'domain',
          sortable: true
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: true
        },
        {
          text: 'Branch',
          value: 'branch',
          sortable: true
        },
        {
          text: 'Version',
          value: 'version',
          sortable: false
        }
      ]
    }
  },
  async created() {
    this.isLoading = true;
    this.cores = await this.getCores();
    this.isLoading = false;
  },
  methods: {
    ...mapActions(['getCores']),
    async refresh() {
      this.isLoading = true;
      this.cores = await this.getCores();
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>

</style>
