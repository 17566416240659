<template>
  <v-card>
    <v-app-bar
        flat
        color="rgba(0, 0, 0, 0)"
    >
      <v-toolbar-title class="headline">
        Footer
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-checkbox
          v-model="data.VIEW.footer.show"
          label="Anzeigen"
          color="green"
          hide-details
      />
    </v-app-bar>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-list-item-group
            multiple
        >
          <v-list-item v-for="(link, index) in data.VIEW.footer.links"
                       v-bind:item="link"
                       v-bind:index="index"
                       v-bind:key="link.label">
            <template>
              <v-list-item-action>
                <!-- Remove button -->
                <v-btn fab
                       depressed
                       small
                       @click="remove(index)"
                >
                  <v-icon :color="color">
                    delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{
                    link.label.includes("|trans") ? link.label + ' (Übersetzung)' : link.label
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ link.link }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn fab
                 depressed
                 small
                 @click="dialog = true"
          >
            <v-icon :color="color">
              add
            </v-icon>
          </v-btn>

          <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="dialog">
            <v-card>
              <v-card-title>
                <span class="headline"> Link hinzufügen </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                          label="Label"
                          v-model="label"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          label="Link"
                          v-model="link"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- Save -->
                <v-btn
                    color="green darken-1"
                    text
                    @click="create">
                  Hinzufügen
                </v-btn>

                <!-- Close -->
                <v-btn
                    color="blue darken-1"
                    text
                    v-on:click="close">
                  Schließen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "FooterConfigurationSection",
  mixins: [ConfigurationMixin],
  data() {
    return {
      dialog: false,

      label: undefined,
      link: undefined
    }
  },
  methods: {
    create() {
      this.data.VIEW.footer.links.push({
        label: this.label,
        link: this.link
      });

      this.close();
    },
    remove(index) {
      this.data.VIEW.footer.links.splice(index, 1);
    },
    close() {
      this.dialog = false;
      this.label = undefined;
      this.link = undefined;
    }
  }
}
</script>

<style scoped>

</style>
