<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="reference" fab depressed light color="lightgray" small v-bind="attrs" v-on="on">
        <v-icon color="black">
          edit
        </v-icon>
      </v-btn>

      <v-btn v-else fab depressed light color="green" small v-bind="attrs" v-on="on">
        <v-icon color="white">
          add
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ reference ? 'Form editieren' : 'Form hinzufügen' }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.name"
                  :rules="nameRules"
                  :color="color"
                  label="Name"
                  prepend-icon="chat"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select v-if="types"
                        :items="types"
                        v-model="type"
                        item-text="name"
                        item-value="type"
                        label="Typ"
                        :color="color"
                        prepend-icon="build"
                        return-object
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-combobox
                  :color="color"
                  v-model="form.channels"
                  :items="form.channels"
                  label="Channels"
                  prepend-icon="summarize"
                  multiple
                  small-chips
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.title"
                  :rules="nameRules"
                  :color="color"
                  prepend-icon="title"
                  label="Überschrift"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.label"
                  :rules="nameRules"
                  :color="color"
                  prepend-icon="label"
                  label="Label"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.email"
                  :rules="emailRules"
                  :color="color"
                  prepend-icon="alternate_email"
                  label="E-mail"
                  required
              />
            </v-col>
          </v-row>

          <!-- USER -->
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.username"
                  prepend-icon="account_circle"
                  label="Username"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.password"
                  prepend-icon="account_circle"
                  label="Password (User)"
                  required
              />
            </v-col>
          </v-row>

          <!-- PRIVACY -->
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.privacy"
                  :rules="nameRules"
                  :color="color"
                  prepend-icon="privacy_tip"
                  label="Datenschutzerklärung (Link)"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.privacy_lable"
                  :rules="nameRules"
                  :color="color"
                  prepend-icon="privacy_tip"
                  label="Datenschutzerklärung (Label)"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.privacy_text"
                  :rules="nameRules"
                  :color="color"
                  prepend-icon="privacy_tip"
                  label="Datenschutzerklärung (Text)"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  label="Download Text"
                  prepend-icon="mdi-comment"
                  v-model="form.download_text"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  label="Meldeperson Text"
                  prepend-icon="mdi-comment"
                  v-model="form.notifier_text"
              ></v-textarea>
            </v-col>
          </v-row>

          <!-- Redirect -->
          <v-row>
            <v-col>
              <v-text-field
                  v-model="form.redirect_url"
                  :rules="nameRules"
                  :color="color"
                  type=""
                  prepend-icon="link"
                  label="Weiterleitung (Link)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  v-model="form.redirect_text"
                  :rules="nameRules"
                  :color="color"
                  prepend-icon="link"
                  label="Weiterleitung (Text)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                  v-model="form.closing_words"
                  :rules="nameRules"
                  :color="color"
                  prepend-icon="mdi-comment"
                  label="Schlusswort (Bilder-Upload)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- Save -->
        <v-btn
            color="green darken-1"
            text
            @click="makeCallback">
          {{ reference ? 'Bearbeiten' : 'Hinzufügen' }}
        </v-btn>

        <!-- Close -->
        <v-btn
            color="blue darken-1"
            text
            v-on:click="dialog = false">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

/** ref Object
 *
 * 'ref': {
 *   'index': '0' // array index
 *   'obj': {     // object reference
 *
 *   }
 * }
 *
 */
import GlobalMixin from "@/components/mixins/GlobalMixin";

import FormService from "@/services/FormService";
import ToastedMixin from "@/components/mixins/ToastedMixin";

const _ = require('lodash');

export default {
  name: "FormDialog",
  props: ['reference'],
  mixins: [GlobalMixin, ToastedMixin],
  data() {
    return {
      dialog: false,
      property: undefined,
      types: [],
      type: undefined,
      form: {
        name: undefined,
        channels: undefined,
        label: undefined,
        title: undefined,
        email: undefined,
        // user stuff
        userToken: undefined,
        username: undefined,
        password: undefined,
        types: undefined,
        privacy: undefined,
        privacy_lable: undefined,
        privacy_text: undefined,
        download_text: undefined,
        notifier_text: undefined,
        redirect_url: undefined,
        redirect_text: undefined,
        closing_words: undefined,
        layout: []
      },
      nameRules: [
        v => !!v || 'Name ist erforderlich'
      ],
      emailRules: [
        v => !!v || 'E-mail ist erforderlich!',
        v => /.+@.+/.test(v) || 'E-mail muss gültig sein',
      ],
    }
  },
  async created() {
    // fetch types
    this.types = await FormService.getTypes();

    if (this.reference && this.reference.obj) {
      this.form = _.cloneDeep(this.reference.obj);

      if (this.form.types) {
        this.type = this.form.types[0];
      }
    }
  },
  methods: {
    makeCallback() {
      if (!this.form.channels)
        this.form.channels = [];

      /*
      if (!this.form.channels || this.form.channels.length === 0) {
        this.sendToast("form_channels_missing", this.TOAST_TYPE.ERROR);
        return;
      }
       */

      if (this.form.name && this.form.email && this.type) {
        this.form.types = typeof this.type === 'string' ? [this.type] : [this.type.type];

        if (this.reference) {
          this.$emit("callback", {
            action: "edit",
            index: this.reference.index,
            form: this.form
          });
        } else {
          this.$emit("callback", {
            action: "add",
            form: this.form
          });
        }

        this.dialog = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
