<template>
  <div class="projects">
    <ProjectListing></ProjectListing>
    <router-view></router-view>
  </div>
</template>

<script>
import ProjectListing from "@/components/project/ProjectListing";

export default {
  name: 'Projects',
  components: {
    ProjectListing
  },
  props: [
    'id'
  ]
}
</script>
