<template>
  <v-app :class="($vuetify.theme.dark ? 'content-dark' : 'content-light')">
    <NavBar :activeUser="activeUser" @logout="logout" @login="login"></NavBar>
    <v-main :class="($vuetify.theme.dark ? 'main-content--dark ' : 'main-content ') + ' pb-5'">
      <v-container class="my-4 px-5">
        <router-view :activeUser="activeUser"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// VueJS
// Libraries
import gravatar from "gravatar";
// Services
// Mixins
import GlobalMixin from "@/components/mixins/GlobalMixin";
// EventBus
// Components
import NavBar from "./components/NavBar";
import VueCookies from "vue-cookies";

export default {
  name: 'App',
  mixins: {
    GlobalMixin
  },
  data: () => ({
    activeUser: null
  }),
  components: {
    NavBar
  },
  async created() {
    this.setStyle();
    await this.refreshActiveUser();
  },
  watch: {
    async $route(to, from) {
      await this.refreshActiveUser();
    }
  },
  methods: {
    setStyle() {
      const style = VueCookies.get('style');
      if (style) {
        // DARK
        if (style.dark.primary) {
          this.$vuetify.theme.themes.dark.primary = style.dark.primary;
        }
        if (style.dark.secondary) {
          this.$vuetify.theme.themes.dark.secondary = style.dark.secondary;
        }

        // LIGHT
        if (style.light.primary) {
          this.$vuetify.theme.themes.light.primary = style.light.primary;
        }
        if (style.light.secondary) {
          this.$vuetify.theme.themes.light.secondary = style.light.secondary;
        }
      }
    },
    login() {
      this.$auth.loginRedirect()
    },
    async refreshActiveUser() {
      await this.$auth.getUser().then(async (result) => {
        this.activeUser = result;

        if (!this.activeUser)
          return;

        this.activeUser.profile_img = gravatar.url(this.activeUser.email, {protocol: 'https'});
      });
    },
    async logout() {
      await this.$auth.logout();
      await this.refreshActiveUser();
      await this.$auth.loginRedirect();
    }
  }
};

</script>

<style scoped lang="css">
.content-dark {
  background-color: #0d1117;
}

.content-light {
  background-color: #efefef;
}
</style>
