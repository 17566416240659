<template>
  <div>
    <h1>Sprachkonstanten</h1>

    <v-container>
      <v-row>
        <v-col cols="5">
          <v-select class="p-0"
                    :items="languageCodes"
                    item-text="Sprache"
                    v-model="languageCode"
                    label="Sprache"
                    prepend-icon="flag"
                    return-object
          />
        </v-col>
        <v-col cols="7" class="text-right">
          <SaveDialog :shouldSave="shouldSave" :tryToQuit="tryToQuit" @save="save"/>
        </v-col>
      </v-row>
      <v-row v-if="languageSet">
        <v-col>
          <v-jsoneditor v-model="languageSet.languageSet" :options="{mode: 'code', onChangeText: jsonChanged}"
                        height="1000px"/>
        </v-col>
      </v-row>
      <v-row v-else-if="languageCode && !loading">
        <v-col class="text-center pt-3">

          <v-icon size="128" class="mb-4" @click="create">post_add</v-icon>
          <div>Es wurden für die Sprache {{ languageCode }} noch keine abweichenden Sprachkonstanten angelegt</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SaveDialog from "../misc/SaveDialog";
import LanguageService from "../../services/LanguageService";
import _ from "lodash";

export default {
  name: "Language",
  components: {SaveDialog},
  data() {
    return {
      loading: false,

      languageCodes: ["DE", "EN", "FR"],
      languageCode: undefined,
      languageSet: undefined,
      languageSetCopy: undefined,
      shouldSave: false,
      tryToQuit: undefined
    }
  },
  computed: {
    selectedProject: {
      get() {
        return this.$store.state.projects.selectedProject;
      }
    }
  },
  watch: {
    async languageCode(newVal, oldVal) {
      this.languageSet = undefined;
      await this.loadLanguageSet(newVal);
    }
  },
  methods: {
    jsonChanged() {
      this.shouldSave = !_.isEqual(this.languageSetCopy.languageSet, this.languageSet.languageSet);
    },
    async create() {
      if (this.languageSet)
        return;

      this.languageSet = await LanguageService.create(this.selectedProject.domain, this.languageCode);
      this.languageSetCopy = _.cloneDeep(this.languageSet);
    },
    async loadLanguageSet(languageCode) {
      this.loading = true;
      this.languageSet = await LanguageService.getLanguage(this.selectedProject.domain, languageCode);
      this.languageSetCopy = _.cloneDeep(this.languageSet);

      this.loading = false;
    },
    async save() {
      if (!this.languageSet)
        return;

      await LanguageService.save(this.languageSet, this.selectedProject.domain);

      this.shouldSave = false;
      this.tryToQuit = undefined;
    }
  }
}
</script>

<style scoped>

</style>
