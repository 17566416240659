import axios from "@/utility/axios";


const url = '/api/core/';

class CoreService {

    static async getCore(id) {
        const coreRes = await axios.get('core', url + 'id/' + id);

        if (!coreRes)
            return null;

        const core = coreRes.data.result;

        if (!core)
            return null;

        // get status
        core.status = await this.getStatus(core.domain);

        return core;
    }

    static getBranches() {
        return new Promise((resolve, reject) => {
            axios.get('core/branches', `https://gitlab.venus-werbung.de/api/v4/projects/131/repository/branches`, {
                headers: {
                    "PRIVATE-TOKEN": "48CLbWbA9XG8XWsd5F1A"
                }
            }).then((res) => {
                resolve(
                    res.data
                )
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /*
    static updateBranch(core, branch, resolve, reject) {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.get('https://' + core + '/we2p/api/setBranch.php', {
            params: {
                cors: true,
                branch: branch
            }
        }).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        });
    }
     */

    static async getStatus(domain) {
        return new Promise((resolve, reject) => {
            axios.get('core/status', 'https://' + domain + '/we2p/api/status.php', {
                params: {
                    cors: true
                }
            }).then((response) => {
                resolve({
                    "configVersion": response.data.components['webmodules-core']['version']['current_config-version'],
                    "branch": response.data.components['webmodules-core']['version']['current_branch'],
                    "currentHash": response.data.components['webmodules-core']['version']['current_hash'],
                    "currentVersion": response.data.components['webmodules-core']['version']['current_version'],
                    "newestHash": response.data.components['webmodules-core']['version']['newest_branch_hash']
                });
            }).catch((error) => {
                reject(error);
            });
        });
    }

    static async getCores() {
        return (await axios.get('core/cores', url + 'all?status=true')).data.result;
    }

    /*
    static getConfigVersion(domain, resolve, reject) {
        const cors = this.dontInjectCORS.includes(domain) ? 'false' : 'true';

        axios.get('https://' + domain + '/we2p/api/?controller=config&action=getVersion', {
            params: {
                cors: cors
            }
        }).then(function (response) {
            resolve(response.data.version);
        }).catch(function (error) {
            reject(
                error
            );
        });
    }
     */
}

export default CoreService
