<template>
  <nav>
    <v-app-bar app dark color="secondary">
      <v-app-bar-nav-icon class="ml-1" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <v-switch
          class="mr-5 mt-5"
          @click="switchMode"
          inset
          :color="$vuetify.theme.dark ? 'blue' : 'white'"
          :prepend-icon="$vuetify.theme.dark ? 'bedtime' : 'wb_sunny'"
      ></v-switch>

      <v-avatar v-if="activeUser" size="40" class="mr-4">
        <img :src="activeUser.profile_img" alt="profile image">
      </v-avatar>
      <span class="text-button mr-3 d-none d-md-block" v-if="activeUser">{{ activeUser.name }}</span>
      <v-btn fab small text v-if="activeUser" @click="$emit('logout')">
        <v-icon>logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app dark color="primary" v-model="drawer">

      <v-list class="pt-0">
        <v-list-item>
          <v-list-item-content class="pt-4 pb-4">
            <v-img
                src="/img/logo_white.png"
                max-width="100"/>
            <h3 class="pl-1">Generator6 V3</h3>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <a class="d-inline-block pt-6 pr-2">
            <Search/>
          </a>

          <v-autocomplete
              :items="allProjects"
              item-text="domain"
              item-value="_id"
              :menu-props="{ bottom: true, offsetY: true }"
              label="Projekt"
              v-model="currentProject"
              hide-details
              return-object
          ></v-autocomplete>
        </v-list-item>

        <v-list-item-group class="mt-2" v-if="currentProject && currentProject._id !== '0'">
          <!-- Show all projects -->
          <v-list-item class="text-decoration-none"
                       :color="hoverColor"
                       router
                       @click="resetSelection"
                       :to="`/project/${currentProject._id}`">
            <v-list-item-icon>
              <v-icon color="white">call_split</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <div style="color: white">Alles anzeigen</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Show all cores -->
          <v-list-item class="text-decoration-none"
                       :color="hoverColor"
                       router
                       @click="resetSelection"
                       :to="`/project/${currentProject._id}/cores`" :exact="true">
            <v-list-item-icon>
              <v-icon color="white">developer_board</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <div style="color: white">Cores anzeigen</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Project menu (Only displayed when a project is selected) -->
          <div v-if="selectedProject && selectedProject._id !== '0'">
            <v-list-group
                :value="true"
                prepend-icon="folder_open"
            >
              <template v-slot:activator>
                <v-list-item-title>Projekt</v-list-item-title>
              </template>

              <!-- Configuration -->
              <v-list-item class="text-decoration-none pl-8"
                           :color="hoverColor"
                           router
                           :to="`/project/${selectedProject._id}/${selectedProject.domain}/config`"
              >
                <v-list-item-icon>
                  <v-icon color="white">settings</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div style="color: white">Config</div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Language -->
              <v-list-item class="text-decoration-none pl-8"
                           :color="hoverColor"
                           router
                           :to="`/project/${currentProject._id}/${currentProject.domain}/language`"
              >
                <v-list-item-icon>
                  <v-icon color="white">language</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div style="color: white">Sprachkonstanten</div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Forms -->
              <v-list-item class="text-decoration-none pl-8"
                           :color="hoverColor"
                           router
                           :to="`/project/${selectedProject._id}/${selectedProject.domain}/forms`"
              >
                <v-list-item-icon>
                  <v-icon color="white">description</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <div style="color: white">Forms</div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list-item-group>

        <!-- CORES -->
        <v-list-item class="text-decoration-none"
                     :color="hoverColor"
                     router
                     to="/cores"
        >
          <v-list-item-icon>
            <v-icon color="white">developer_board</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <div style="color: white">Cores</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- FILES -->
        <v-list-item class="text-decoration-none"
                     :color="hoverColor"
                     router
                     to="/files"
        >
          <v-list-item-icon>
            <v-icon color="white">text_snippet</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <div style="color: white">Dateien</div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">

          <v-dialog
              v-model="dialog"
              persistent
              max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="white" text @click="dialog = true">
                <v-icon size="25">
                  settings
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">Einstellungen</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- DARK -->
                    <v-col cols="6">
                      <p>Primärfarbe (Darkmode)</p>
                      <ColorInput v-model="style.dark.primary"
                                  :default="style.dark.primary">
                      </ColorInput>
                    </v-col>
                    <!-- LIGHT -->
                    <v-col cols="6">
                      <p>Primärfarbe (Lightmode)</p>
                      <ColorInput v-model="style.light.primary"
                                  :default="style.light.primary">
                      </ColorInput>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- DARK -->
                    <v-col cols="6">
                      <p>Sekundärfarbe (Darkmode)</p>
                      <ColorInput v-model="style.dark.secondary"
                                  :default="style.dark.secondary">
                      </ColorInput>
                    </v-col>

                    <!-- LIGHT -->
                    <v-col cols="6">
                      <p>Sekundärfarbe (Lightmode)</p>
                      <ColorInput v-model="style.light.secondary"
                                  :default="style.light.secondary">
                      </ColorInput>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text v-on:click="dialog = false">Schließen</v-btn>
                <v-btn color="orange" text v-on:click="resetSettings()">Zurücksetzen</v-btn>
                <v-btn color="success" depressed v-on:click="saveSettings()">Speichern</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import GlobalMixin from "@/components/mixins/GlobalMixin";
import ColorInput from "@/components/misc/ColorInput";
import Search from "@/components/misc/Search";

export default {
  name: "NavBar",
  components: {Search, ColorInput},
  mixins: [GlobalMixin],
  props: ['activeUser'],
  data() {
    return {
      dark: false,
      drawer: true,
      hoverColor: '',
      dialog: false,
      style: {
        dark: {
          primary: this.$vuetify.theme.themes.dark.primary,
          secondary: this.$vuetify.theme.themes.dark.secondary
        },
        light: {
          primary: this.$vuetify.theme.themes.light.primary,
          secondary: this.$vuetify.theme.themes.light.secondary
        }
      }
    }
  },
  created() {
    this.dark = this.$cookies.get('dark') === "true";
    this.$vuetify.theme.dark = this.dark;
    this.hoverColor = this.$vuetify.theme.dark ? '#586069' : 'secondary';
  },
  computed: {
    ...mapGetters(["allProjects"]),
    currentProject: {
      get() {
        return this.$store.state.projects.currentProject;
      },
      set(project) {
        this.selectedProject = undefined;
        this.$router.push(`/project/${project._id}`);

        this.setCurrentProject(project);
      }
    },
    selectedProject: {
      get() {
        return this.$store.state.projects.selectedProject;
      },
      set(project) {
        this.setSelectedProject(project);
      }
    }
  },
  watch: {
    $route: function (newVal) {
      if (!this.projects)
        this.fetchProjects();
    }
  },
  methods: {
    ...mapActions(["fetchProjects", "setCurrentProject", "setSelectedProject"]),
    resetSettings() {
      this.set(this.$vuetify.theme.defaults);
      Object.assign(this.style, this.$vuetify.theme.defaults);
      this.$cookies.set('style', this.style);
      this.dialog = false;
    },
    saveSettings() {
      this.set(this.style);
      this.$cookies.set('style', this.style);
      this.dialog = false;
    },
    switchMode() {
      this.dark = !this.dark;
      this.$vuetify.theme.dark = this.dark;
      this.$cookies.set('dark', this.dark);
      this.hoverColor = this.dark ? '#586069' : 'secondary';
    },
    set(obj) {
      if (obj) {
        // DARK
        if (obj.dark.primary) {
          this.$vuetify.theme.themes.dark.primary = obj.dark.primary;
        }
        if (obj.dark.secondary) {
          this.$vuetify.theme.themes.dark.secondary = obj.dark.secondary;
        }

        // LIGHT
        if (obj.light.primary) {
          this.$vuetify.theme.themes.light.primary = obj.light.primary;
        }
        if (obj.light.secondary) {
          this.$vuetify.theme.themes.light.secondary = obj.light.secondary;
        }
      }
    },
    resetSelection() {
      this.selectedProject = undefined;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
