import axios from "axios";

const requests = [];

const axiosInstance = axios.create({
    withCredentials: false,
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:2000' : (process.env.VUE_APP_GENERATOR ? `${process.env.VUE_APP_GENERATOR}` : 'https://generator.we2p.de')
});

//axiosInstance, axios
export default {
    get(name, path) {
        //this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.get(path, {cancelToken: request.token});
    },
    put(name, path, data = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.put(path, data, {cancelToken: request.token});
    },
    post(name, path, data = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.post(path, data, {cancelToken: request.token});
    },
    patch(name, path, data = {}) {
        this.cancel(name);

        const request = axios.CancelToken.source();
        requests.push({name: name, request: request});
        return axiosInstance.patch(path, data, {cancelToken: request.token});
    },
    cancel(name) {
        for (let entry of requests.map((value, index) => ({index, value}))) {
            if (entry.value.name === name) {
                entry.value.request.cancel();
                requests.splice(entry.index, 1);
            }
        }
    }
}
