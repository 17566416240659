<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Schriften
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container class="pl-4 pr-4">
      <v-row>
        <v-col>
          <v-text-field :label="'Überschrift (' + defaultData.VIEW.styles.fonts.headline + ')'"
                        required
                        v-model="data.VIEW.styles.fonts.headline"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :label="'Text (' + defaultData.VIEW.styles.fonts.text + ')'"
                        required
                        v-model="data.VIEW.styles.fonts.text"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "FontConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
