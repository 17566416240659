<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Bookmarks
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col cols="4">
          <div class="font-weight-light">Elemente</div>
          <v-checkbox
              v-model="data.VIEW.bookmarks.headline"
              label="Headline"
              color="green"
              hide-details
          />
        </v-col>

        <v-col cols="4">
          <div class="font-weight-light">Icons</div>
          <v-checkbox
              v-model="data.VIEW.bookmarks.buttons.print"
              label="Print"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.bookmarks.menu"
              label="Menü"
              color="green"
              hide-details
          />
        </v-col>

        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.bookmarks.buttons.maps"
              label="Maps"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
        </v-col>

        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.bookmarks.buttons.pdf"
              label="PDF"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
        </v-col>

        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.bookmarks.buttons.clear"
              label="Clear"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "BookmarksConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>
.col {
  padding-top: 0 !important;
}
</style>
