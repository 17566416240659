<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="green" class="ma-2 white--text">
        Export
        <v-icon right dark>
          mdi-cloud-download
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Export {{ config._id }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="config._id + '.json'" v-model="exportName">
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text v-on:click="close()">Close</v-btn>
        <v-btn color="success" depressed v-on:click="exportConfig()">Export</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// VueJS
// Libraries
import cleanDeep from "clean-deep";

const _ = require('lodash');
import {mapGetters} from 'vuex';
// Services
// Mixins
// EventBus
// Components

export default {
  name: "ConfigExport",
  props: [
    'config'
  ],
  data() {
    return {
      dialog: false,
      exportName: undefined
    }
  },
  created() {
  },
  methods: {
    /**
     * Exports the config.
     */
    exportConfig() {
      // remove empty, null or undefined fields
      this.config.config = cleanDeep(this.config.config)
      // merge with default
      const data = JSON.stringify(this.config.config);
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'), a = document.createElement('a');
      a.download = (this.exportName ? this.exportName : this.config._id) + ".json";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
      close();
    },
    /**
     * Closes the export dialog and resets all variables.
     */
    close() {
      this.exportName = undefined;
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
