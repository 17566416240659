import axios from "@/utility/axios";

const url = '/api/project/';

class ProjectService {

    static dontInjectCORS = [
        'www.bergstrasse-odenwald.de'
    ]

    // GET projects
    static getProjects() {
        return new Promise((resolve, reject) => {
            axios.get('projects', url).then((res) => {
                resolve(res.data.result)
            }).catch((err) => {
                reject(err);
            })
        });
    }

    // GET project by id
    static getProjectById(id) {
        return new Promise((resolve, reject) => {
            axios.get('projects/id', url + "id/" + id).then((res) => {
                resolve(res.data.result)
            }).catch((err) => {
                reject(err);
            })
        });
    }

    static getCoresById(id) {
        return new Promise((resolve, reject) => {
            axios.get('projects/cores', url + "cores/id/" + id).then((res) => {
                resolve(res.data.result)
            }).catch((err) => {
                reject(err);
            })
        });
    }

    // GET project where domain
    static getProjectWhereDomain(domain) {
        return new Promise((resolve, reject) => {
            axios.get('projects/domain', url + "/domain/" + domain).then((res) => {
                const data = res.data.result;
                if (res.data.code === 200) {
                    if (data.domain === domain) {
                        resolve({
                            _id: data._id,
                            domain: domain,
                            config: data.config.config,
                            translationTypes: data.config.translationTypes,
                            description: data.description,
                            cores: data.cores,
                            tags: data.tags,
                            addon: false
                        });
                        return;
                    } else {
                        if (data.configCollection) {
                            data.configCollection.some((collection) => {
                                if (collection.domain === domain) {
                                    resolve({
                                        _id: data._id,
                                        domain: domain,
                                        config: collection.config,
                                        translationTypes: collection.translationTypes,
                                        addon: true
                                    });
                                    return true;
                                }
                            });
                            return;
                        }
                    }
                }

                resolve(
                    null
                )
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static async getTemplates() {
        return new Promise((resolve, reject) => {
            axios.get('projects/templates', url + "/templates").then((res) => {
                resolve(res.data.result);
            });
        });
    }

    static async getProjectByCore(id) {
        return new Promise((resolve, reject) => {
            axios.get('projects/core', `${url}/core/${id}`).then((res) => {
                resolve(res.data.result);
            });
        });
    }

    // CREATE project
    static insertProject(project) {
        return axios.post('projects/insert', url, project);
    }

    // UPDATE project
    static updateProject(project) {
        return axios.put('projects/update', `${url}${project._id}`, {
            "name": project.name,
            "domain": project.domain,
            "config": project.config,
            "information": project.information,
            "addons": project.addons
        });
    }

    // DELETE project
    static deleteProject(project) {
        return axios.delete('projects/delete', `${url}${project._id}`);
    }
}

export default ProjectService;
