import router from '../../router';
import ProjectService from "@/services/ProjectService";

// STATE
const initialState = () => ({
    projects: [],
    currentProject: {
        _id: "0",
        name: 'Bitte wählen...',
    },
    selectedProject: {
        _id: "0"
    }
});

export default {
    state: initialState(),
    getters: {
        allProjects: state => state.projects,
        currentProject: state => state.currentProject,
        selectedProject: state => state.selectedProject
    },
    actions: {
        async fetchProjects({commit}) {
            const response = await ProjectService.getProjects();
            commit('setProjects', response);
        },
        setCurrentProject({commit}, project) {
            commit('setCurrentProject', project)
        },
        setSelectedProject({commit}, project) {
            commit('setSelectedProject', project)
        },
        reset({commit}) {
            commit('RESET');
        },
    },
    mutations: {
        setProjects: ((state, projects) => {
            state.projects = projects;
            if (projects.length > 0) {
                if (state.currentProject._id === "0") {
                    const project = projects.filter(obj => {
                        return obj._id === router.currentRoute.params.id;
                    });

                    if (project[0])
                        state.currentProject = project[0];
                }
            }
        }),
        setCurrentProject: (state, project) => state.currentProject = project,
        setSelectedProject: (state, project) => state.selectedProject = project,
        RESET(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        }
    }
}
