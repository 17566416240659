<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Daten
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col cols="4">
          <v-combobox
              :color="color"
              v-model="data.DATA.global_filter.projects"
              :items="data.DATA.global_filter.projects"
              label="Projects"
              multiple
              small-chips
          />
        </v-col>
        <v-col cols="4">
          <v-combobox
              :color="color"
              v-model="data.DATA.global_filter.channels"
              :items="data.DATA.global_filter.channels"
              label="Channels"
              multiple
              small-chips
          />
        </v-col>
        <v-col cols="4">
          <v-combobox
              :color="color"
              v-model="data.DATA.global_filter.exclude_types"
              :items="data.DATA.global_filter.exclude_types"
              label="Exclude types"
              multiple
              small-chips
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <!-- API Keys -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                  :color="color"
                  label="Satellite API-Key"
                  required
                  type="text"
                  v-model="data.MAP.satApiKey"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-text-field
              :color="color"
              :label="'Paging limit (' + defaultData.DATA.paging_limit + ')'"
              required
              type="number"
              v-model="data.DATA.paging_limit"
          />
        </v-col>
      </v-row>

    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "dataConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
