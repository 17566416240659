<template>
  <div class="p-0 m-0">
    <v-btn v-if="shouldSave"
           fab
           depressed
           color="green"
           small
           @click="save">
      <v-icon color="white">
        save
      </v-icon>
    </v-btn>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="dialog">

      <v-card>
        <v-card-title>
          <span class="headline">Speichern</span>
        </v-card-title>

        <v-card-text>
          <div class="body-1">Wollen Sie die Änderungen speichern?</div>
          <div class="body-2">Alle nicht gespeicherten Änderungen gehen verloren!</div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Ignore -->
          <v-btn
              color="red darken-1"
              text
              @click="dismiss()"
          >
            Verwerfen
          </v-btn>

          <!-- Save -->
          <v-btn
              color="green darken-1"
              text
              @click="save()"
          >
            Speichern
          </v-btn>

          <!-- Close -->
          <v-btn
              color="blue darken-1"
              text
              v-on:click="dialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "SaveDialog",
  props: [
    'shouldSave',
    'tryToQuit'
  ],
  data() {
    return {
      route: undefined,
      dialog: false,
      test: false
    }
  },
  created() {
    window.addEventListener('beforeunload', this.check);
  },
  watch: {
    tryToQuit: {
      handler(next) {
        if (this.shouldSave) {
          this.dialog = true;
        } else {
          this.dialog = false;

          if (next) {
            next();
          }
        }
      }
    }
  },
  methods: {
    save() {
      this.dialog = false;
      this.$emit('save', true, this.tryToQuit)
    },
    dismiss() {
      this.dialog = false;
      this.$emit('save', false, this.tryToQuit);
    },
    check() {
      if (this.shouldSave) {
        return "Dude, are you sure you want to leave? Think of the kittens!";
      }
    }
  }
}
</script>

<style scoped>

</style>
