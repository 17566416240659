const mixin = {
    data() {
        return {
            TOAST_TYPE: {
                SUCCESS: 'success',
                ERROR: 'error'
            },
            messages_: new Map([
                ["project_update", {
                    error: "could not update project!",
                    success: "updated project."
                }],
                ["project_delete", {
                    error: "could not delete project!",
                    success: "project deleted."
                }],
                ["project_cache", {
                    error: "could not clear cache!",
                    success: "cleared cache."
                }],
                ["load_configs", {
                    error: "could not load configs!",
                    success: "loaded configs.."
                }],
                ["config_update", {
                    error: "could not update config!",
                    success: "updated config."
                }],
                ["config_delete", {
                    error: "could not delete config!",
                    success: "deleted config."
                }],
                ["configs_delete", {
                    error: "could not delete configs!",
                    success: "deleted configs."
                }],
                ["configs_import", {
                    error: "could not import config!",
                    success: "imported config."
                }],
                ["core_status", {
                    error: "could not get status from core!",
                    success: "status request successfully."
                }],
                ["project_create", {
                    error: "could not create project!",
                    success: "created Project successfully!"
                }],
                ["file_missing_attribute", {
                    error: "a type and language must be specified!",
                    success: "file successfully loaded!"
                }],
                ["not_saved", {
                    error: "changes not saved!",
                    success: "changes saved!"
                }],
                ["form_channels_missing", {
                    error: "a channel must be specified (did you press enter?)!",
                    success: "channel successfully set!"
                }],
            ])
        }
    },
    methods: {
        /**
         * Sends an toast.
         * @param template {String}
         * @param type {String}
         */
        sendToast(template, type) {
            const message = this.getMessage(template);
            if (message) {
                switch (type) {
                    case "success":
                        this.$toasted.global.success(message.success);
                        break;
                    case "error":
                        this.$toasted.global.error(message.error);
                        break;
                }
            }
        },
        /**
         * Returns the message for the given template.
         * @param template {String}
         * @returns {Object}
         */
        getMessage(template) {
            return this.messages_.get(template);
        }
    }
};

export default mixin;
