<template>
  <div v-if="!isLoading">
    <v-container v-if="config && !config.default" style="padding: 0;">

      <!--
      **********************************
      *             DATA               *
      **********************************
      -->
      <v-row>
        <v-col>
          <DataConfigurationSection :data="config.config"
                                    :default-data="defaultConfig.config"
                                    :color="color"
                                    @changed="changed"
          />
        </v-col>
      </v-row>

      <!--
      **********************************
      *            STYLE               *
      **********************************
      -->
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="6">
              <ColorConfigurationSection :data="config.config"
                                         :default-data="defaultConfig.config"
                                         :color="color"
                                         @changed="changed"
              />
            </v-col>

            <v-col cols="6">
              <SpacingConfigurationSection :data="config.config"
                                           :default-data="defaultConfig.config"
                                           :color="color"
                                           @changed="changed"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <FontConfigurationSection :data="config.config"
                                    :default-data="defaultConfig.config"
                                    :color="color"
                                    @changed="changed"
          />
        </v-col>

        <!--
        **********************************
        *            LISTING             *
        **********************************
        -->
        <v-col cols="6">
          <ListingConfigurationSection :data="config.config"
                                       :default-data="defaultConfig.config"
                                       :color="color"
                                       @changed="changed"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <DetailConfigurationSection
              :data="config.config"
              :default-data="defaultConfig.config"
              :color="color"
              @changed="changed"/>
        </v-col>

        <!--
       **********************************
       *              FORMS             *
       **********************************
       -->
        <v-col cols="6">
          <FormsConfigurationSection :data="config.config"
                                     :default-data="defaultConfig.config"
                                     :color="color"
                                     @changed="changed"
          />
        </v-col>
      </v-row>

      <v-row>
        <!--
        **********************************
        *           BOOKMARKS            *
        **********************************
        -->
        <v-col cols="4">
          <BookmarksConfigurationSection :data="config.config"
                                         :default-data="defaultConfig.config"
                                         :color="color"
                                         @changed="changed"
          />
        </v-col>

        <!--
        **********************************
        *              MAPS              *
        **********************************
        -->
        <v-col cols="8">
          <MapsConfigurationSection :data="config.config"
                                    :default-data="defaultConfig.config"
                                    :color="color"
                                    @changed="changed"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <DiscussionsConfigurationSection :data="config.config"
                                           :default-data="defaultConfig.config"
                                           :color="color"
                                           @changed="changed"
          />
        </v-col>
      </v-row>

      <v-row>
        <!--
       **********************************
       *             TERMINAL           *
       **********************************
       -->
        <v-col cols="6">
          <TerminalConfigurationSection :data="config.config"
                                        :default-data="defaultConfig.config"
                                        :color="color"
                                        @changed="changed"
          />
        </v-col>

        <!--
       **********************************
       *             FOOTER             *
       **********************************
       -->
        <v-col cols="6">
          <FooterConfigurationSection :data="config.config"
                                      :default-data="defaultConfig.config"
                                      :color="color"
                                      @changed="changed"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--
       **********************************
       *             COMET              *
       **********************************
       -->
        <v-col cols="4">
          <CometConfigurationSection :data="config.config"
                                     :default-data="defaultConfig.config"
                                     :color="color"
                                     @changed="changed"
          />
        </v-col>

        <!--
       **********************************
       *             RES              *
       **********************************
       -->
        <v-col cols="4">
          <ResultConfigurationSection :data="config.config"
                                      :default-data="defaultConfig.config"
                                      :color="color"
                                      @changed="changed"
          />
        </v-col>
      </v-row>

      <!--
      **********************************
      *            ACTIONS             *
      **********************************
      -->
      <v-row>
        <v-col cols="6" class="text-left">
          <ConfigImport :config="config.config" :structure="configStructure" @callback="override"/>
          <ConfigExport :config="config"/>
        </v-col>

        <v-col cols="6" class="text-right">
          <SaveDialog :shouldSave="shouldSave" :tryToQuit="tryToQuit" @save="save"/>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row>
        <v-col class="text-center">
          <v-btn
              style="height: 150px; width: 150px;"
              icon
              @click="open"
          >
            <v-icon size="128">
              post_add
            </v-icon>
          </v-btn>

          <h3>Es wurde noch keine Config hinterlegt!</h3>

          <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="dialog">
            <v-card>
              <v-card-title>
                <span class="headline"> Config hinzufügen </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select style="max-width: 300px"
                                :items="templates"
                                item-text="domain"
                                v-model="template"
                                label="Config (Template)"
                                prepend-icon="folder"
                                :rules="typeRules"
                                return-object
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- Save -->
                <v-btn
                    color="green darken-1"
                    text
                    @click="create">
                  Erstellen
                </v-btn>

                <!-- Close -->
                <v-btn
                    color="blue darken-1"
                    text
                    v-on:click="dialog = false">
                  Schließen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ConfigService from "@/services/ConfigService";
import GlobalMixin from "@/components/mixins/GlobalMixin";
import ConfigImport from "@/components/project/configuration/ConfigImport";
import ConfigExport from "@/components/project/configuration/ConfigExport";
import SaveDialog from "@/components/misc/SaveDialog";
import ProjectService from "@/services/ProjectService";
import {mapActions} from "vuex";
import DataConfigurationSection from "@/components/project/configuration/sections/DataConfigurationSection";
import ColorConfigurationSection from "@/components/project/configuration/sections/ColorConfigurationSection";
import SpacingConfigurationSection from "@/components/project/configuration/sections/SpacingConfigurationSection";
import FontConfigurationSection from "@/components/project/configuration/sections/FontConfigurationSection";
import ListingConfigurationSection from "@/components/project/configuration/sections/ListingConfigurationSection";
import DetailConfigurationSection from "@/components/project/configuration/sections/DetailConfigurationSection";
import BookmarksConfigurationSection from "@/components/project/configuration/sections/BookmarksConfigurationSection";
import FormsConfigurationSection from "@/components/project/configuration/sections/FormsConfigurationSection";
import FooterConfigurationSection from "@/components/project/configuration/sections/FooterConfigurationSection";
import MapsConfigurationSection from "@/components/project/configuration/sections/MapsConfigurationSection";
import CometConfigurationSection from "@/components/project/configuration/sections/CometConfigurationSection";
import TerminalConfigurationSection from "@/components/project/configuration/sections/TerminalConfigurationSection";
import ToastedMixin from "@/components/mixins/ToastedMixin";
import DiscussionsConfigurationSection from "./sections/DiscussionsConfigurationSection";
import ResultConfigurationSection from "@/components/project/configuration/sections/ResultConfigurationSection.vue";

const _ = require('lodash');

export default {
  name: "Configuration",
  components: {
    ResultConfigurationSection,
    DiscussionsConfigurationSection,
    TerminalConfigurationSection,
    CometConfigurationSection,
    MapsConfigurationSection,
    FooterConfigurationSection,
    FormsConfigurationSection,
    DetailConfigurationSection,
    BookmarksConfigurationSection,
    ListingConfigurationSection,
    FontConfigurationSection,
    SpacingConfigurationSection,
    ColorConfigurationSection, DataConfigurationSection, SaveDialog, ConfigExport, ConfigImport
  },
  mixins: [
    GlobalMixin,
    ToastedMixin
  ],
  data() {
    return {
      config: undefined,
      defaultConfig: undefined,
      isLoading: false,
      tryToQuit: undefined,
      savedConfig: {},
      configStructure: {
        DATA: {
          global_filter: {
            projects: [],
            channels: [],
            exclude_types: []
          },
          paging_limit: undefined,
        },
        VIEW: {
          styles: {
            margin: {
              left: undefined,
              right: undefined,
              top: undefined,
              bottom: undefined
            },
            colors: {
              primary: undefined,
              secondary: undefined,
              background: undefined,
              white: undefined,
              black: undefined
            },
            fonts: {
              headline: undefined,
              text: undefined
            }
          },
          maps: {
            header: true,
            menu: true
          },
          listing: {
            menu: true,
            header: true,
            buttons: {
              bookmarks: true,
              maps: true,
              search: true
            },
            filters: {
              topic: true,
              city: true,
              type: true
            }
          },
          detail: {
            header: {
              size: undefined,
              position: undefined
            },
            slider: {
              backgroundImage: true
            },
            nearby: {
              hide: false
            },
            recommendations: {
              hide: false
            },
            "show": {
              "types": true,
              "classification": true,
              "created": true,
              "name": true,
              "cityAdministrationType": true,
              "author": true,
              "trail": true,
              "shortDescription": true,
              "description": true,
              "personCapacity": true,
              "address": true,
              "cityInfo": true,
              "logo": true,
              "eventStatus": true,
              "topics": true,
              "languages": true,
              "knowsLanguage": true,
              "availableLanguage": true,
              "cuisine": true,
              "audience": true,
              "slider": true,
              "smallSlider": true,
              "accessibilityDescription": true,
              "member": true,
              "sellsBrands": true,
              "recipe": true,
              "timeline": true,
              "itinerary": true,
              "subEvent": true,
              "location": true,
              "organizer": true,
              "performer": true,
              "containsPlace": true,
              "subjectOf": true,
              "accommodations": true,
              "bookingLinksSpecifications": true,
              "snowReports": true,
              "openingHours": true,
              "fruitVariety": true,
              "foodEstablishment": true,
              "specialDescription": true,
              "features": true,
              "productsDirectMarketer": true,
              "servicesDirectMarketer": true,
              "priceSpecification": true,
              "contactPoint": true,
              "parkingPossibilities": true,
              "arrivalDescription": true,
              "map": true,
              "downloads": true,
              "links": true,
              "share": true
            }
          },
          bookmarks: {
            headline: true,
            menu: true,
            buttons: {
              print: true,
              maps: true,
              pdf: true,
              clear: true
            }
          },
          forms: {
            show: true,
            url: undefined,
            icon: "fal fa-plus"
          },
          footer: {
            show: true,
            links: [
              {
                "label": "general.privacy_imprint|trans",
                "link": "https://www.we2p.de/?page_id=3"
              }
            ]
          }
        },
        RES: {
          error_pages: {
            '404': null
          }
        },
        MAP: {
          debug: false,
          limit: null,
          minZoom: null,
          maxZoom: null,
          maxBounds: null,
          elevationProfile: true,
          center: undefined,
          trail: {
            color: undefined,
            width: undefined
          },
          satApiKey: undefined,
          nearby: {
            foodEstablishment: {
              filter: undefined,
              color: undefined
            },
            lodgingBusiness: {
              filter: undefined,
              color: undefined
            },
            touristAttraction: {
              filter: undefined,
              color: undefined
            },
            trail: {
              filter: undefined,
              color: undefined
            },
            oepnv: {
              filter: undefined,
              color: undefined,
              image: undefined
            }
          },
        },
        COMET: {
          url: undefined,
          client: undefined,
          domain: undefined,
          project_identifier: undefined
        },
        TERMINAL: {
          active: false,
          external: undefined,
          position: {
            longitude: undefined,
            latitude: undefined
          },
          returnButton: true
        },
        DISCUSSIONS: {
          project: undefined,
          channel: undefined,
          active: false,
          comment_section: {
            active: false,
            env: "live",
            types: [],
            answer_icon_url: undefined,
            rounding: true
          }
        }
      },

      // create dialog
      dialog: false,
      templates: undefined,
      template: undefined,
      configs: undefined
    }
  },
  async created() {
    await this.initialize();
  },
  beforeRouteLeave(to, from, next) {
    if (this.selectedProject && this.selectedProject.config) {
      // save callback for save dialog
      this.tryToQuit = next;
    } else {
      next();
    }
  },
  computed: {
    selectedProject: {
      get() {
        return this.$store.state.projects.selectedProject;
      }
    }
  },
  watch: {
    'config.config': {
      handler(data) {
        if (!this.shouldSave) {
          if (!_.isEqual(this.savedConfig, data)) {
            // If something has been changed, the save button is displayed at the bottom right.
            this.shouldSave = true;
          }
        }
      },
      deep: true
    },
    async selectedProject(newObj, oldObj) {
      if (oldObj._id === "0") {
        await this.initialize();
      }
    }
  },
  methods: {
    ...mapActions(["setSelectedProject"]),
    async initialize() {
      this.isLoading = true;
      await this.getConfig();

      if (!this.config) {
        // fetch configs for template selection
        this.templates = await ProjectService.getTemplates();
      }

      this.shouldSave = false;
    },
    async getConfig() {
      // get project config
      this.defaultConfig = await ConfigService.getDefaultConfig();
      if (this.selectedProject.config) {
        this.config = await ConfigService.getConfig(this.selectedProject.config);

        if (this.config && !this.config.default) {
          this.prepare();
        }
      }

      this.isLoading = false;
    },
    prepare() {
      if (this.config) {
        // Merge custom config with template
        this.config.config = _.merge(this.configStructure, this.config.config);
        this.config = JSON.parse(JSON.stringify(this.config)); // watch does not work without this diversions
        this.savedConfig = _.cloneDeep(this.config.config); // use object assign to avoid references
      }
    },
    save(action, next) {
      // if true save current record
      if (action) {
        ConfigService.updateConfig(this.selectedProject.domain, {
          _id: this.config._id,
          config: this.config.config
        });
      } else {
        this.config.config = _.cloneDeep(this.savedConfig);
      }

      this.shouldSave = false;

      // If next isn't null, the user tries to save the record manually
      if (next) {
        // redirect to route
        next();
      }

      this.sendToast("config_update", this.TOAST_TYPE.SUCCESS);
    },
    /**
     * used for config import
     * @param config
     */
    override(config) {
      this.shouldSave = true;
      this.config.config = config;
    },
    open() {
      if (this.templates) {
        this.dialog = true;
      } else {
        // not templates available, create config directly
        this.create();
      }
    },
    async create() {
      let config;
      if (this.template) {
        config = await ConfigService.createWithTemplateConfig(this.selectedProject.domain, this.template.id);
      } else {
        config = await ConfigService.createConfig(this.selectedProject.domain);
      }

      if (config && config._id) {
        this.selectedProject.config = config._id;
        this.setSelectedProject(this.selectedProject);

        this.config = config;
        this.prepare();
      }

      this.sendToast("project_create", this.TOAST_TYPE.SUCCESS);
      this.dialog = false;
    },
    changed(obj) {
      this.config.config = obj;
    }
  }
}
</script>

<style scoped>

</style>
