<template>
  <div class="backwards">
    <a @click="$router.go(-1)" class="mb-4 grey--text text-body-2 text-uppercase d-inline-block">
      <v-icon color="grey">arrow_back</v-icon>
      zurück
    </a>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Backwards"
}
</script>

<style scoped>

</style>
