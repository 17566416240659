<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog">

    <template v-slot:activator="{ on, attrs }">
      <v-btn fab
             depressed
             :color="btnColor"
             small
             v-bind="attrs"
             v-on="on"
             @click="open()">
        <v-icon :color="color">
          {{ btnIcon }}
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Löschen</span>
      </v-card-title>

      <v-card-text>
        <div class="body-1">Wollen Sie diesen Datensatz wirklich löschen?</div>
        <div class="body-2">Gelöschte Datensätze können nicht wiederhergestellt werden!</div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- Delete -->
        <v-btn
            color="red darken-1"
            text
            @click="deleteData">
          Löschen
        </v-btn>

        <!-- Close -->
        <v-btn
            color="blue darken-1"
            text
            v-on:click="dialog = false">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: [
    'byPassConfirmation',
    'criteria'
  ],
  data() {
    return {
      dialog: false,

      // Button style
      btnColor: 'red',
      btnIcon: 'delete',
      color: 'white'
    }
  },
  created() {
    const attrs = this.$attrs.attrs;
    if (attrs) {
      if (attrs.btnColor) {
        this.btnColor = attrs.btnColor
      }

      if (attrs.btnIcon) {
        this.btnIcon = attrs.btnIcon;
      }

      if (attrs.color) {
        this.color = attrs.color
      }
    }
  },
  methods: {
    open() {
      if (this.byPassConfirmation) {
        this.$emit("delete", {
          criteria: this.criteria,
          delete: true
        });
      } else {
        this.dialog = true;
      }
    },
    dismiss() {
      this.$emit("delete", {
        criteria: this.criteria,
        delete: false
      });
    },
    deleteData() {
      this.$emit("delete", {
        criteria: this.criteria,
        delete: true
      });
    }
  }
}
</script>

<style scoped>

</style>
