<template>
  <div>
    <v-container class="my-5">
      <v-card flat class="pa-3 mb-1" :color="background"
              v-for="(core, index) in cores"
              v-bind:item="core"
              v-bind:index="index"
              v-bind:key="core._id">
        <v-layout row wrap class="px-4">
          <!-- ROW 1 -->
          <v-flex xs12 md5>
            <div class="caption grey--text">Core ({{ core._id }})</div>
            <p class="text-h5 mb-0">
              <v-btn fab text small dense :color="color" router
                     :to="`/project/${id}/cores/${core._id}`">
                <v-icon>mode_edit</v-icon>
              </v-btn>

              {{ core.domain }}
            </p>
          </v-flex>

          <!-- ROW 2 -->
          <v-flex xs6 sm4 md2>
            <div class="py-1" v-for="(tag, index) in core.tags"
                 v-bind:item="tag"
                 v-bind:index="index"
                 v-bind:key="tag">
              <v-chip outlined>{{ tag }}</v-chip>
            </div>
          </v-flex>

          <!-- ROW 2 -->
          <v-flex xs6 sm4 md2>
            <v-container full-height>
              <div class="caption grey--text">Status</div>
              <div v-if="core.offlineSince">
                <div class="grey--text">
                  <v-icon color="red">mobiledata_off</v-icon>
                  {{ convertDate(core.offlineSince) }}
                </div>

              </div>
              <div v-else>
                <div class="grey--text">
                  <v-icon color="green">router</v-icon>
                  Online
                </div>
              </div>
            </v-container>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>

    <v-progress-linear v-if="isLoading"
                       indeterminate
                       color="blue"
                       height="6">
    </v-progress-linear>
  </div>
</template>

<script>
// Mixins
import GlobalMixin from "@/components/mixins/GlobalMixin";
import ProjectService from "@/services/ProjectService";

export default {
  name: "CoreListing",
  mixins: [
    GlobalMixin
  ],
  data() {
    return {
      id: undefined,
      cores: [], // as array
      isLoading: false
    }
  },
  async created() {
    this.isLoading = true;
    await this.getCores();
  },
  computed: {
    currentProject: {
      get() {
        return this.$store.state.projects.currentProject;
      }
    }
  },
  watch: {
    async currentProject(newObj, oldObj) {
      if (oldObj._id === "0") {
        this.isLoading = true;
        await this.getCores();
      }
    }
  },
  methods: {
    async getCores() {
      this.cores = await ProjectService.getCoresById(this.currentProject._id);
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>

</style>
