<template>
  <div>
    <v-container v-if="core">
      <v-row>
        <v-col>
          <h2>Core</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div class="font-weight-medium">Domain</div>
          <a :href='"https://" + core.domain' target="_blank">
            {{ core.domain }}
          </a>
        </v-col>
        <v-col cols="4">
          <div class="font-weight-medium">Erstellt</div>
          <div>
            {{ convertDate(core.createdAt) }}
          </div>

          <!-- Updated -->
          <div class="font-weight-medium">Aktualisiert</div>
          <div>
            {{ convertDate(core.updatedAt) }}
          </div>
        </v-col>
        <v-col cols="4" v-if="core.status">
          <v-row>
            <v-col>
              <div class="font-weight-medium">Branch</div>
              <div>
                {{ core.status.branch }}
              </div>

              <div class="font-weight-medium">Version</div>
              <div>
                {{ core.status.currentVersion }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" v-else>

        </v-col>
      </v-row>
    </v-container>

    <v-divider class="mt-5 mb-5"></v-divider>
  </div>
</template>

<script>
import CoreService from "@/services/CoreService";
import GlobalMixin from "@/components/mixins/GlobalMixin";
import ToastedMixin from "@/components/mixins/ToastedMixin";

export default {
  name: "Core",
  mixins: [GlobalMixin, ToastedMixin],
  data() {
    return {
      id: undefined,
      core: undefined
    }
  },
  async created() {
    this.id = this.$router.currentRoute.params.coreId
    this.core = await CoreService.getCore(this.id);

    if (!this.core.status) {
      this.sendToast("core_status", this.TOAST_TYPE.ERROR);
    }
  }
}
</script>

<style scoped>

</style>
