<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Terminal
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col>
          <v-switch
              label="aktivieren?"
              v-model="data.TERMINAL.active"
          ></v-switch>
        </v-col>
      </v-row>
      <div v-if="data.TERMINAL.active">
        <v-row>
          <v-col>
            <v-text-field
                label="Externe Website"
                required
                v-model="data.TERMINAL.external"
            />
          </v-col>
          <v-col>
            <v-switch
                label="History Button"
                v-model="data.TERMINAL.returnButton"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                label="Terminal Längengrad"
                required
                v-model="data.TERMINAL.position.longitude"
            />
          </v-col>
          <v-col>
            <v-text-field
                label="Terminal Breitengrad"
                required
                v-model="data.TERMINAL.position.latitude"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "TerminalConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
