<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Listing
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col cols="4">
          <div class="font-weight-light">Elemente</div>
          <v-checkbox
              v-model="data.VIEW.listing.menu"
              label="Menü"
              color="green"
              hide-details
          />
        </v-col>

        <v-col cols="4">
          <div class="font-weight-light">Icons</div>
          <v-checkbox
              v-model="data.VIEW.listing.buttons.bookmarks"
              label="Bookmarks"
              color="green"
              hide-details
          />
        </v-col>

        <v-col cols="4">
          <div class="font-weight-light">Filter</div>
          <v-checkbox
              v-model="data.VIEW.listing.filters.topic"
              label="Topic"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.listing.header"
              label="Header"
              color="green"
              hide-details
          />
        </v-col>

        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.listing.buttons.maps"
              label="Maps"
              color="green"
              hide-details
          />
        </v-col>

        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.listing.filters.city"
              label="City"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4"></v-col>

        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.listing.buttons.search"
              label="Suche"
              color="green"
              hide-details
          />
        </v-col>

        <v-col cols="4">
          <v-checkbox
              v-model="data.VIEW.listing.filters.type"
              label="Type"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "ListingConfigurationSection",
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>
.col {
  padding-top: 0 !important;
}
</style>
