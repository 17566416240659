<template>
  <v-text-field
      :value="tColor"
      readonly d
      isabled
      hide-details
      class="ma-0 pa-0"
      solo
  >
    <template v-slot:append>
      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on"/>
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
                v-model="tColor"
                hide-mode-switch
                mode="hexa"
                :value="tColor"
                v-on:input="emitChange($event)"
                flat>
            </v-color-picker>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import GlobalMixin from "@/components/mixins/GlobalMixin";

export default {
  name: "ColorInput",
  mixins: [GlobalMixin],
  data() {
    return {
      menu: false,
      tColor: '#FF0000'
    }
  },
  props: [
    'value',
    'default'
  ],
  created() {
    if (this.value) {
      this.tColor = this.value;
    } else if (this.default) {
      this.tColor = this.default;
    }
  },
  watch: {
    value: function (newVal) {
      this.tColor = newVal;
    }
  },
  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.tColor,
        border: (this.dark ? 'white' : 'black') + ' solid 1px',
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: this.menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  methods: {
    emitChange(e) {
      this.$emit('input', e);
    }
  }
}
</script>

<style scoped>

</style>
