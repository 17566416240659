<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Detailseite
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container class="pl-4 pr-4">
      <v-row>
        <v-col cols="6">
          <v-text-field :label="'Header (' + defaultData.VIEW.detail.header.size + ')'"
                        required
                        v-model="data.VIEW.detail.header.size "
          />
        </v-col>
        <v-col cols="6">
          <v-text-field :label="'Header Position (' + defaultData.VIEW.detail.header.position + ')'"
                        required
                        v-model="data.VIEW.detail.header.position"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-checkbox
              v-model="data.VIEW.detail.slider.backgroundImage"
              label="Slider Hintergrundbild"
              color="green"
              hide-details
          />
        </v-col>
        <v-col cols="6">
          <v-checkbox
              v-model="data.VIEW.detail.nearby.hide"
              label="Recommendations nicht anzeigen?"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
        </v-col>

        <v-col cols="6">
          <v-checkbox
              v-model="data.VIEW.detail.recommendations.hide"
              label="'Das könnte Dir auch gefallen' nicht anzeigen?"
              color="green"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="headline">Elemente auf Detailseite</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <div>Hier können die einzelnen Elemente auf der Detailseite deaktiviert werden.</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <v-btn
                        @click="switchShow(true)"
                        color="green"
                        hide-details
                    >
                      Alle aktivieren
                    </v-btn>
                  </v-col>
                  <v-col class="text-center">
                    <v-btn
                        @click="switchShow(false)"
                        color="red"
                        hide-details
                    >
                      Alle deaktivieren
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-for="(key, index) of Object.keys(data.VIEW.detail.show)" :key="index" cols="4">
                    <v-checkbox
                        v-model="data.VIEW.detail.show[key]"
                        :label="key"
                        color="green"
                        hide-details
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "DetailConfigurationSection",
  mixins: [ConfigurationMixin],
  methods: {
    switchShow(state) {
      for (const key of Object.keys(this.data.VIEW.detail.show)) {
        this.data.VIEW.detail.show[key] = state;
      }
    }
  }
}
</script>

<style scoped>

</style>
