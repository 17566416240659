<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="800px">
    <template v-slot:activator="{ attrs }">
      <v-btn v-bind="attrs" @click="pick" color="blue-grey" class="ma-2 white--text">
        Import
        <v-icon right dark>
          mdi-cloud-upload
        </v-icon>
      </v-btn>

      <input
          type="file"
          style="display: none"
          ref="fileInput"
          accept="application/json"
          @change="select"/>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ file ? file.name : 'Error' }}</span>
      </v-card-title>
      <v-card-text>
        <div v-if="!jsonError" class="text-success">Valid JSON ✔</div>
        <div class="text-danger" v-if="jsonError">{{ jsonError }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text v-on:click="close()">Close</v-btn>
        <div v-if="!jsonError">
          <v-btn class="orange--text" color="transparent" depressed v-on:click="override()">Override</v-btn>
          <v-btn class="green--text" color="transparent" depressed v-on:click="merge()">Merge</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// VueJS
// Libraries
const _ = require('lodash');
// Services
// Mixins
import GlobalMixin from "@/components/mixins/GlobalMixin";

export default {
  name: "ConfigImport",
  mixins: [
    GlobalMixin
  ],
  props: [
    "config",
    "structure"
  ],
  data() {
    return {
      dialog: false,
      file: undefined,
      jsonError: undefined,
      imported: undefined
    }
  },
  methods: {
    /**
     * Closes the import dialog and resets all variables
     */
    close() {
      this.dialog = false;
      this.file = undefined;
      this.jsonError = undefined;
    },
    /**
     * Overrides the current config with the imported one.
     */
    override() {
      if (!this.config) return;

      this.$emit("callback", _.merge(this.structure, this.imported));
      this.send("config_import", this.TOAST_TYPE.SUCCESS);
      this.close();
    },
    /**
     * Merges the current config with the imported one.
     */
    merge() {
      if (!this.config) return;

      this.$emit("callback", _.merge(this.imported, this.config));
      this.sendToast("config_import", this.TOAST_TYPE.ERROR);
      this.close();
    },
    /**
     * Opens the file dialog.
     */
    pick() {
      this.$refs.fileInput.click()
    },
    /**
     * Is called when the user has selected a file.
     * @param e {Object}
     */
    select(e) {
      const files = e.target.files

      if (!files) return (this.dialog = false);

      let reader = new FileReader();
      reader.onload = e => {
        try {
          // try to parse
          this.imported = JSON.parse(e.target.result)
          this.file = files[0];
        } catch (e) {
          this.jsonError = e.message;
          this.sendToast("config_import", this.TOAST_TYPE.ERROR);
        }

        this.dialog = true;
      };

      reader.readAsText(files[0]);
    }
  }
}
</script>

<style scoped>

</style>
