<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      scrollable
      v-model="dialog">

    <template v-slot:activator="{ on, attrs }">
      <v-btn class="bg-transparent"
             fab
             depressed
             small
             v-bind="attrs"
             color="transparent"
             v-on="on"
             @click="dialog = true">
        <v-icon color="white">search</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Suche</span>
      </v-card-title>

      <v-card-text style="height: 350px">
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-select
                  v-model="scope"
                  :items="scopes"
                  label="Suche in..."
              ></v-select>
            </v-col>
            <v-col cols="8">
              <v-text-field
                  v-model="query"
                  label="Nach..."
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="searchResults.length <= 0">
            <v-col class="text-center">
              <v-icon size="100">error_outline</v-icon>
              <h3 class="pt-1">Nichts gefunden</h3>
            </v-col>
          </v-row>

          <v-card flat class="pa-3 mb-1"
                  v-for="(result, index) in searchResults"
                  v-bind:item="result"
                  v-bind:index="index"
                  v-bind:key="result._id">
            <v-layout row wrap class="px-4">
              <!-- ROW 1 -->
              <v-flex>
                <a @click="changeRoute(result)">
                  <div class="caption grey--text">
                    {{ result._id }}
                  </div>

                  <p class="text-h5 mb-0">
                    {{ result.domain }}
                  </p>
                </a>
              </v-flex>
            </v-layout>
          </v-card>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- Search -->
        <v-btn
            color="green darken-1"
            text
            @click="search"
            @keyup.enter="search">
          Suchen
        </v-btn>

        <!-- Close -->
        <v-btn
            color="blue darken-1"
            text
            v-on:click="dialog = false">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchService from "@/services/SearchService";
import ProjectService from "@/services/ProjectService";
import {mapActions} from "vuex";

export default {
  name: "Search",
  data() {
    return {
      dialog: false,
      scope: undefined,
      scopes: ["project", "core"],
      query: undefined,
      searchResults: []
    }
  },
  methods: {
    ...mapActions(["setCurrentProject", "setSelectedProject"]),
    async search() {
      const result = await SearchService.search(this.scope, this.query);
      this.searchResults = result ? result : [];
    },
    async changeRoute(entry) {
      let routePath, project;

      switch (this.scope) {
        case 'project':
          routePath = `/project/${entry._id}`;

          if (this.$route.path === routePath) {
            this.close();
            return;
          }

          this.setSelectedProject(undefined);
          this.setCurrentProject({_id: entry._id});

          await this.$router.push(routePath);
          this.close();
          break;
        case 'core':
          // get project for core
          project = await ProjectService.getProjectByCore(entry._id);

          if (project) {
            routePath = `/project/${project._id}/cores/${entry._id}`;

            if (this.$route.path === routePath) {
              this.close();
              return;
            }

            this.setSelectedProject(undefined);
            this.setCurrentProject({_id: project._id});

            await this.$router.push(routePath);
            this.close();
          }

          break;
      }
    },
    close() {
      this.query = undefined;
      this.scope = undefined;
      this.dialog = false;
      this.searchResults = [];
    }
  }
}
</script>

<style scoped>

</style>
