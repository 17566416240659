<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">
          Farben
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-container v-if="data" class="pl-4 pr-4">
      <v-row>
        <v-col>
          <div class="caption grey--text">Primärfarbe ({{
              defaultData.VIEW.styles.colors.primary
            }})
          </div>
          <ColorInput v-model="data.VIEW.styles.colors.primary"
                      :default="data.VIEW.styles.colors.primary"
          />
        </v-col>

        <v-col>
          <div class="caption grey--text">Sekundärfarbe ({{
              defaultData.VIEW.styles.colors.secondary
            }})
          </div>
          <ColorInput v-model="data.VIEW.styles.colors.secondary"
                      :default="defaultData.VIEW.styles.colors.secondary"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="caption grey--text">Schwarz ({{
              defaultData.VIEW.styles.colors.black
            }})
          </div>
          <ColorInput v-model="data.VIEW.styles.colors.black"
                      :default="defaultData.VIEW.styles.colors.black"
          />
        </v-col>

        <v-col>
          <div class="caption grey--text">Weiß ({{
              defaultData.VIEW.styles.colors.white
            }})
          </div>
          <ColorInput v-model="data.VIEW.styles.colors.white"
                      :default="defaultData.VIEW.styles.colors.white"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div class="caption grey--text">Hintergrundfarbe ({{
              defaultData.VIEW.styles.colors.background
            }})
          </div>
          <ColorInput v-model="data.VIEW.styles.colors.background"
                      :default="defaultData.VIEW.styles.colors.background"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ColorInput from "@/components/misc/ColorInput";
import ConfigurationMixin from "@/components/mixins/ConfigurationMixin";

export default {
  name: "ColorConfigurationSection",
  components: {ColorInput},
  mixins: [ConfigurationMixin]
}
</script>

<style scoped>

</style>
